export const getLangData = () => [
        {
        id:"general",
        name:"General Languages",
        details:
        [
            {
                lang:"Divinus",
                type:"Spoken / Written",
                descr:"Language of aasimars and eventide aasimars, and an official language of various religious circles and some of the cults."
            },
            {
                lang:"Dwarven",
                type:"Spoken / Written",
                descr:"Common language of dwarves that inhabit various dwarven enclaves across the world. Old Dwarven is a very rare dialect of the dwarven language that is used among older dwarven population. There is no known records of someone learning or using it outside of enclaves."
            },
            {
                lang:"Elvish",
                type:"Spoken / Written",
                descr:"Language of elvenfolk, commonly used across Triumvirate of Arsaria. A realm that generally opposes northern nations and mainly consists of elven population, including drows and maelstrom elves. For the most part, elves use High Elvish dialect. Low Elvish is a mix of High Elvish with some other local language and thus, it is not very accepted among higher elven ranks."
            },
            {
                lang:"Exerian",
                type:"Spoken / Written",
                descr:"Language of the Western League, an isolationist trade union located in the deserts of Ekithir continent. Exerian is quite rarely heard and used outside of the League itself, so it is quite a difficult task to learn it."
            },
            {
                lang:"Ignan",
                type:"Spoken / Written / Exclusive to tieflings",
                descr:"Language of tieflings granted them by Vigsla, Goddess of Pacts as a punishment for denouncing her millenias ago. Only tieflings are able to learn and speak and write in Ignan. Following spells will not work when trying to understand this language: Comprehend Languages, Tongues."
            },
            {
                lang:"Kor Signage",
                type:"Somatic only / Exclusive to kor",
                descr:"Sign language used by the indigenous race of Kor that originated in Koris Strait."
            },
            {
                lang:"Korisian",
                type:"Spoken / Written / Common language of the world",
                descr:"Also referred to as Trade Speech, is an official language of the City-State of Koris, an important trading hub at the center of Hordarat. Korisian is commonly used across the world as a multinational language. High Korisian dialects used among nobility of Koris, as well as in the diplomatic circles and high offices."
            },
            {
                lang:"Klaki",
                type:"Spoken / Written",
                descr:"Language of the Kindred Houses of Trygg, direct ancestor to Osilian"
            },
            {
                lang:"Osilian",
                type:"Spoken / Written",
                descr:"Language of the Lathagane Empire, a very young and ambitious nation that is known for its rather straightforward approach to diplomacy and war and for its incredible stonework and fortifications. Originated from Klaki mixed with the local languages of Kingships of Osilius."
            },
            {
                lang:"Titan Speech",
                type:"Spoken only",
                descr:"Language used among goliath nomadic clans and some of the dwarves. Does not have writing system."
            },
        ]
    },
    {
        id:"other",
        name:"Special Languages",
        details:
        [
            {
                lang:"Blood Script",
                type:"Somatic / Written",
                descr:"Visual language that consists of communicating with circular symbols made of blood. Blood signs also used as verbal and somatic components of the blood magic spells."
            },
            {
                lang:"Druidic",
                type:"Spoken / Written",
                descr:"Used in various druid communities"
            },
            {
                lang:"Metal Anthem",
                type:"Spoken / Written",
                descr:"Divine language used by Twin Gods themselves. Writing system represented as an amalgamation of vertical and horizonal constructions made of thin lines and curves, both starting from the center of the page and expanding in all directions. Only the most devoted worshipers might hear gods' speech in their visions, but records of such experinces are incredibly rare."
            },
            {
                lang:"Thieves’s Cant",
                type:"Spoken / Written / Somatic",
                descr:"Used by various criminal elements across the world. This speech also has visual and somatic aspects to it, allowing for a quick communication without a sound."
            },
            {
                lang:"Void Whisper",
                type:"Spoken / Written",
                descr:"incomprehensible language of the Void beings (demons mostly). Has its own writing system that contains of continious horizontal lines interjecting each other."
            },
        ]
    },
    {
        id:"dead",
        name:"Dead Tongues",
        details:
        [
            {
                lang:"Thanasian",
                type:"Spoken / Written",
                descr:"Language of the Thanasimos Ascension, a forgone empire that spanned over now Desolate Vales of Rudr. Direct ancestor of the Exerian and Korisian."
            },
            {
                lang:"???",
                type:"Unknown",
                descr:"To be discovered..."
            },
        ]
    },
    
];
