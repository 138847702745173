import { NavLink } from 'react-router-dom';
import './Empires.css';
import React, { Fragment } from 'react';
import { PUBLICURL } from '../data/const';
import Collapsable from '../Collapsable/Collapsable';

class Empires extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeIndex: "none"
    };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(empire) {
    this.setState({ 
      activeIndex: empire
    })
  }

  render() {

    return (
    <>
      <div className="empires-menu">
        <div className="empires-list"> 
        {
          this.props.data.sort((a, b) => a.name.localeCompare(b.name)).map(t => 
          <div className={this.state.activeIndex === t.name ? "empires-container-active" : "empires-container" } onClick={() => this.handleClick(t.name)}>
            <img src={PUBLICURL + t.logo} />
            <div className="god-name">{t.name}</div>
          </div> 
          )
        }
        </div>
        <div className="empire-details"> 
        {
            this.state.activeIndex === "none" ?
            <div className="empty">
              <img src={PUBLICURL + "empty-states/empty-normal.png"} />
              Select item from the list
            </div> :
            this.props.data.filter(text => text.name===this.state.activeIndex).map(t => 
              <Fragment key={t.id}>
                <img src={PUBLICURL + t.logo} id="emp-logo-details"/>
                <div className="details-name">{t.name}</div>
                <div className="details-alias">{t.alias}</div>
                <div className="details-keywords">
                  {
                    t.keywords.map(y =>
                      <div className="details-keyword">{y.key}</div>
                      )
                  }
                </div>
                {
                    t.details.map(z =>
                      <div className="details-empire-descr">
                        <Collapsable name="History" subtitle="" descr={z.history} defaultExpanded={true}></Collapsable>
                        <Collapsable name="Goal" subtitle="" descr={z.goal}></Collapsable>
                        <Collapsable name="Capital & Government" subtitle="" descr={z.capitalGovern}></Collapsable>
                        <Collapsable name="Climate & Landscape" subtitle="" descr={z.climate}></Collapsable>
                        <Collapsable name="Official Languages" subtitle="" descr={z.lang}></Collapsable>
                        <Collapsable name="Religion" subtitle="" descr={z.religion}></Collapsable>
                        <Collapsable name="Military" subtitle="" descr={z.military}></Collapsable>
                        <Collapsable name="Economics" subtitle="" descr={z.economics}></Collapsable>
                        <Collapsable name="Law" subtitle="" descr={z.law}></Collapsable>
                        <Collapsable name="Magic" subtitle="" descr={z.magic}></Collapsable>
                      </div>
                      )
                  }

              </Fragment>)
          }
          </div>
      </div>
    </>
  );
  }
}

export default Empires;