export const getQuestsData = () => [
    {
        questID:"twins",
        name:"Twin-linked",
        participants:"Active",
        objective:"- Explore a new area beneath Atheneum in Himeldan",
        description:" "
    },
    {
        questID:"background",
        type:"hooks",
        name:"Other plot hooks",
        participants:"Secondary",
        objective:"- Find more about Bronx' past and his missing bagpipe\n- Find more about the Samar's dagger and her vision \n- Find Aedis & Firmi's parents\n- Follow-up on clues & items from Darius Fers' hideout \n - Talk to Kor elders about Sekath \n - Find more about Pale Trees \n - Participate in Forum tournaments \n - Explore Koris Strait\n - Find out what happened to Sonya",
        description:" "
    },

]
/* 

    {
        questID:"background",
        type:"hooks",
        name:"Identify items",
        participants:"Aedis / Firmi / Bronx / Samar / Sonya",
        objective:"- No new items to identify -",
        description:" ",
        ifActive: false
    },
        {
        questID:"side",
        type:"secondary",
        name:"Private matters", 
        participants:"Aedis / Firmi / Bronx / Samar",
        objective:"- Find more about Bronx' past and his missing bagpipe\n- Find more about the Samar's dagger and her vision \n- Find Aedis & Firmi's parents",
        description:" "
    },
*/