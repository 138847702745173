export const getRacesData = () => [
    {
        id:"aasimars",
        name:"Aasimars",
        variants:[
            {
                variantName:"Aasimars",
                variantDescr:"Childern of Timthirial, god of day and night, of light and darkness, aasimars are angel-like creatures that first embraced the All-Pantheon, starting the mass worship of it in Hordarat. Besithra Domain, ancient aasimar homeland, was the first known theocracy, making it the center of the religious world. \n \n Aasimars are often zealous towards their religion and incredibly fierce towards various dangerous religious cults they constantly trying to eradicate. But for the most part, aasimars tend to take the roles of profound scholars, historians, priests and healers.",
                languages:[
                    {
                        language: "Korisian"
                    },
                    {
                        language: "Divinus"
                    },
                ],
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to CHA, +1 to INT or WIS"
                            },
                        ]
                    },
                    {
                        featureName:"Under The Gaze of The Clockmaster",
                        bullets:[
                            {
                                bullet:"You get a free Light cantrip. This cantrip does not count towards known cantrips."
                            },
                            {
                                bullet:"You gain darkvision (60ft)"
                            }
                        ]
                    },
                    {
                        featureName:"Divine Focus",
                        bullets:[
                            {
                                bullet:"Aasimars’ ability to channel divine energy makes them very versatile. You have a pool of divine focus points that is equal to your level + Charisma modifier. This pool restores at every dawn."
                            },
                            {
                                bullet:"You can use those points to empower your actions - Weapon attack, Spellcasting or Divine Generosity",
                            },
                            {
                                bullet:"Weapon attack - you can add your points to your melee or ranged damage as necrotic damage with 1:1 conversion.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Spellcasting  - you can spent full pool of your points to gain additional 5 ft of range for spells and cantrips. It also applies to spells and cantrips that require touch.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Divine Generosity  - you can spent full pool of your points to grant advantage to your or other creature’s roll.",
                                isMultiLevel: "true",
                            }
                        ]
                    },
                    {
                        featureName:"Damage Resistances",
                        bullets:[
                            {
                                bullet:"You gain resistance to necrotic and radiant damage."
                            }
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Divinus, Korisian"
                            }
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 30ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Eventide Aasimars (Alternative)",
                variantWarning:"This is an alternative race. Alternative races replace base ones.",
                variantDescr:"Common folk often forgets that Timthirial represents domains other than just light. He also stands for darkness, pre-determination, beginning and end, and those aspects are of a great interest for eventide aasimars. \n \n They tend to be more secretive, introverted and more conservative than their regular counterparts. ",
                languages:[
                    {
                        language: "Korisian"
                    },
                    {
                        language: "Divinus"
                    },
                ],
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to CHA, +1 to STR or DEX"
                            }
                        ]
                    },
                    {
                        featureName:"Under The Cloak of The Clockmaster",
                        bullets:[
                            {
                                bullet:"You get a free Mage Hand cantrip. This cantrip does not count towards known cantrips."
                            },
                            {
                                bullet:"You gain darkvision (60ft)"
                            }
                        ]
                    },
                    {
                        featureName:"Divine Focus",
                        bullets:[
                            {
                                bullet:"Alternatively to regular aasimars, eventide aasimars get a similar pool of divine focus. You have a pool of divine focus points that is equal to your level + CHA modifier. This pool restores at every midnight."
                            },
                            {
                                bullet:"You can use those points to empower your actions - Weapon attack, Excommunication or Illusiveness",
                            },
                            {
                                bullet:"Weapon attack: you can add your points to your melee or ranged damage as necrotic damage with 1:1 conversion.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Excommunication: you can spent full pool of your points to use Burst of Exclusion ability - your divine connection sometimes can aid you when you least expect it. When you are being hit or grappled within melee range, you can use your reaction and avoid this attack or the grapple by pushing all creatures within 5ft range away for 10 ft with magical force push. You can use this ability once per long rest.)",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Illusiveness: you can spent full pool of your points to inflict a disadvantage on other creature’s roll.",
                                isMultiLevel: "true",
                            }
                        ]
                    },
                    {
                        featureName:"Damage Resistances",
                        bullets:[
                            {
                                bullet:"You gain resistance to necrotic and radiant damage."
                            }
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Korisian, Divinus"
                            }
                        ]
                    },
                ],
            },
        ],
    },
    {
        id:"dwarves",
        name:"Dwarves ",
        variants:[
            {
                variantName:"Dwarves ",
                variantDescr:"Intelligent and ancient as the earth itself, dwarves remain one of unresolved mysteries of the world due to the well-kept secrecy of their history and ways of life. Despite being almost alien in nature, they manage to integrate in every society, providing their incredible skill, while still keeping independence and mainly inhabitting their various Enclaves across the world.",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to CON and WIS",
                            },
                        ]
                    },
                    {
                        featureName:"Children of Stone",
                        bullets:[
                            {
                                bullet:"During long rests, instead of sleeping you can choose to turn into stone and rejuvenate in a meditative state, while not moving. You are able to hear  around you normally and see things that are in the direction of your locked gaze.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Turning into stone takes 1 action. Turning back to normal state takes 1 bonus action.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"When turned into stone, you get immunity against poison and resistance to acid, fire and cold damage",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You cannot be petrified",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Your unarmed attacks deal additional 1d4 damage while in normal form",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You can add double your proficiency bonus to the Intelligence (History) checks related to the stonework",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Korisian, Dwarven (young), Old Dwarven (old)"
                            },
                        ]
                    },
                    {
                        featureName:"Tools Proficiency",
                        bullets:[
                            {
                                bullet:"Mason’s tools"
                            },
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 25ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Guardian of the Stone",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"Ancient bloodline of warriors and generals is very pronounced in you. Milllenias of experience documented and passed from generation to generation provide necessary tools to master the nuances of combat. These skills are useful to repel the enemies of the dwarves, as well as to keep the secrets of your people from spilling out into the world.",
                languages:"",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to STR and DEX",
                            },
                        ]
                    },
                    {
                        featureName:"Ancient Combat Manuals",
                        bullets:[
                            {
                                bullet:"You gain proficiency with heavy armor, 2 martial weapons of your choosing or shields. Heavy armor does not grant disadvantage on stealth checks.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You gain proficiency with heavy armor, 2 martial weapons of your choosing or shields. Heavy armor does not grant disadvantage on stealth checks.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You can add double of your proficiency bonus to Intelligence (History) checks related to military history and combat",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You are able to carry very heavy objects",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 30ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Dwarven Sage",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"Dwarves posses immense volumes of knowledge they have collected over the centuries, all thanks to the of meticulous and tireless work of dwarven scholars and caretakers. You are one of those guardians of knowledge that keep your people from making the mistakes of the past.",
                languages:"",
                variantFeatures:[
                    {
                        featureName:"Caretaker of the Lore",
                        bullets:[
                            {
                                bullet:"You gain +1 to INT and WIS",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You are proficient with 2 tools of your choice",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You can choose between an expertise in History or Investigation",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You gain a Keen Mind feat",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                ],
            },
        ],
    },
    {
        id:"elves",
        name:"Elves ",
        variants:[
            {
                variantName:"Elves ",
                variantDescr:"Elves of the southern Triumvirate of Arsaria represent a very ambition nation. Barely hiding their disregard towards other cultures up north, this ancient race is constantly trying to powerplay. However, the tension between Arsaria and other nations does not really represent the entire situation. Common elvenfolk does not always share the same resentment towards others, while the elite is constantly trying to shift the balance of power in the world. Thus, seeing an elf in other parts of the world is not considered unusual, nor it always leads to hostility.",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to DEX, +1 to WIS or INT or CHA"
                            },
                        ]
                    },
                    {
                        featureName:"Self-reflection",
                        bullets:[
                            {
                                bullet:"Instead of sleeping, you can medite and reflect upon your knowledge and experiences. Meditation lasts for 4 hours, and you gain the same benefit of a full 8-hour long rest."
                            },
                            {
                                bullet:"Almost endless pool of life experiences opens a lot of doors for you. You can move a proficiency from one skill to another once every 3 days, and it takes a full long rest to do so."
                            },
                        ]
                    },
                    {
                        featureName:"Cosmic Anchoring",
                        bullets:[
                            {
                                bullet:"Your first death does not lead to your ultimate end. Upon death, your consciousness leaves your body and stays in the endless Astral Sea beyond Hordarat. You are not able to speak or move. Your body serves as an anchor. You return to your body after 24 hours with 1 hit point and 2 exhastion points. In case the body is destroyed, you return in a completely new body with different appearance."
                            },
                            {
                                bullet:"Upon following deaths you must roll 1d100. Rolling above 20 will result in a proper death, while rolling equal or under 20 will trigger the effect of the anchoring."
                            },
                        ]
                    },
                    {
                        featureName:"Ageless Mind",
                        bullets:[
                            {
                                bullet:"You have advantage on saving throws against being charmed, and magic cannot put you to sleep."
                            }
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Korisian, High Elvish (noble), Low Elvish (lowborn)"
                            }
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 30ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Drow Elf",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"Drows are rare among elvenfolk, but their psychic potential is well known. In some cases, it scares even their own people, especially those in the higher ranks of elven society.",
                languages:"",
                variantFeatures:[
                    {
                        featureName:"Flawed Anchor",
                        bullets:[
                            {
                                bullet:"You must roll d100 on every death. Result equal or below 10 will lead to resurrection."
                            }
                        ]
                    },
                    {
                        featureName:"Mind Ripper",
                        bullets:[
                            {
                                bullet:"You can select one creature within 60 ft radius and detect surface level thoughts once per short rest."
                            },
                            {
                                bullet:"You gain Memory Tear ability:",
                            },
                            {
                                bullet:"You can choose one simple enough memory in target’s mind and completely rip it out from their mind for a period of time. You must take a contested roll of 1d20 + your Wisdom modifier versus target’s WIS saving throw. On success, you remove the memory for 1 day, on fail targets feels a slight headache and is aware of attempted intrusion. If target rolls below 5, it additionally takes 1d10 psychic damage. Ability takes a full action to apply, can be used once a day and restores after a long rest.",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                ],
            },
            {
                variantName:"Maelstrom Elf",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"Maelstrom elves represent the ultimate fury of elven nature as their control over lightning often informs their wildcard behavior. During combat encounters with other nations, maelstrom elves are often considered incredibly dangerous and frequently become primary targets during the battle.",
                languages:"",
                variantFeatures:[
                    {
                        featureName:"Gift of The Tempest Rider",
                        bullets:[
                            {
                                bullet:"You gain a free Tempestous Step ability:"
                            },
                            {
                                bullet:"You can teleport up to 30 feet to an unoccupied space that you can see. Upon teleportation, you release a static charge that causes 3d6 lightning damage to up to 2 targets of your choice within 10ft within your destination spot. Targets must succeed a Dexterity save against your spell DC to receive half damage, or full damage on fail. Ability can be used 3 times a day as a bonus action, and can be restored after a long rest.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You are immune to lightning damage and resistant to thunder damage."
                            },
                        ]
                    },
                ],
            },
        ],
    },
    {
        id:"goliaths",
        name:"Goliaths",
        variants:[
            {
                variantName:"Goliaths",
                variantDescr:"There is a an opinion that the nationless nomadic goliath clans are the true children of the Hordarat. Free from most of the worries of the civilized world, some of them serve as spiritual guides that deliver the word of their deities to others, some are searching for the signs of divine presence, some are looking for the glory of combat. \n \n Strong family bonds and freedom of expression are very valued among goliaths as they stand by their freedom.",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to CON, +1 to WIS or STR"
                            },
                        ]
                    },
                    {
                        featureName:"Art of Daj’vat",
                        bullets:[
                            {
                                bullet:"Secret goliath body artistry, also known as Daj’vat,  serves as a heraldy of the clans and also infuses its wearer with unique abilities. The location of a tattoo on your body grants different bonuses. Select one body part that will have a permanent tattoo:",
                            },
                            {
                                bullet:"Head: +1 to Intelligence or Wisdom, advantage on Medicine and Nature checks, darkvision (60ft)",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Face: +1 to Charisma, advantage on Deception and Insight checks",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Torso: + 1 to Constitution, resistance to cold and bludgeoning damage types, you can reduce incoming damage by 1d12 as a reaction once per short rest",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Back: +1 to Strength, advantage on grapple attempts, ability to carry very heavy objects",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Arms: +1 to Dexterity, proficiency in Althletics or Sleight of Hand skills, unarmed attacks deal double damage",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Legs: your base walking speed is 35 ft, proficiency in Acrobatics",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                    {
                        featureName:"Nomadic Wisdom",
                        bullets:[
                            {
                                bullet:"You are an expert in Survival skill. You are also able to tell the time and northern direction at all times."
                            },
                            {
                                bullet:"You have a natural ability to sense and approximate your clan’s  whereabouts."
                            }
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Korisian, Titan Speech"
                            },
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 30ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Nomadic Ancestry",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"You are a part of larger family that extends beyong your clan. You are tied to an ancient dynasty by blood, and that connection grants you recognition, respect and sometimes infamy among your people and others.",
                languages:"",
                variantFeatures:[
                    {
                        featureName:"Goliath Dynasties",
                        bullets:[
                            {
                                bullet:"Select one of the following dynasties:"
                            },
                            {
                                bullet:"Varla’Kaj, Masters of Ink: you are able to add a tattoo on another body part.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Dan’Kaj, The Elders: +1 to Intelligence, you also gain one free cantrip or spell from any spell list. This cantrip or spell does not count towards known or prepared spells or cantrips.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Ur’Kaj, Eternal Travelers: you cannot die from exhaustion, you gain two additional hit dice that have special properties. In addition to the default function, you can use those hit dice to relief one exhaustion point during short rest, or apply as healing when stabilizing some other creature. You restore one of those dice per long rest.",
                                isMultiLevel: "true",
                            }
                        ]
                    },
                ],
            },
        ],
    },
    {
        id:"humans",
        name:"Humans",
        variants:[
            {
                variantName:"Humans",
                variantDescr:"One of the most populated and short-lived race, humans tend to be the most diverse and the most adaptive of all other cultures. Their rich history, ever-presence, and versatility enables them often to become pioneers, achievers, innovators and adventurers. ",
                variantFeatures:[
                    {
                        featureName:"Jack of All Trades",
                        bullets:[
                            {
                                bullet:"Humans’ ubiquity serves them well. You gain +1 to all abilities and a proficiency in one of the skills of your choosing."
                            }
                        ]
                    },
                    {
                        featureName:"Citizens of the World",
                        bullets:[
                            {
                                bullet:"You get additional bonuses based on your place of origin in human-dominated cultures. All origins will also gain the knowledge of the language:"
                            },
                            {
                                bullet:"Western League: +1 to Wisdom & Constitution or Dexterity, +3 to passive perception, proficiency in Insight & Arcana, resistance to fire damage, natural adaptation to hot climate.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"City-state of Koris: +1 to Charisma & Dexterity, proficiency in Persuasion & Insight, additional language of your choice, first point of exhaustion can be removed after a short rest.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Kindred Houses of Trygg: +1 to Strenght & Consitution, proficiency in Survival and Nature skills, two martial weapons of your choosing and shields, base speed is 35ft, resistance to cold damage, natural adaptation to cold climate.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Eastern Marches: +1 to Charisma & Intelligence, additional feat, proficiency in History, Religion & Medicine, one free cantrip from Cleric spell list.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Custom place of birth: consult with DM.",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Based on origin (check Citizens of the World feature)"
                            }
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 30ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Old Blood",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"Certain lineages stretch up to the first humans ever existed, to the times when gods and mortals were closer and mortals were much more powerful, stronger and more ambitious with their desires. This ambition and this strenght is still present in the blood that flows inside of some descendants",
                languages:"",
                variantFeatures:[
                    {
                        featureName:"Old Blood",
                        bullets:[
                            {
                                bullet:"Your strong physique gives you get resistance against poison and advantage on Constitution saving throws that are not concentration checks.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You gain +1 to one ability of your choice",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Life expectancy is x2 of a regular human",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Proficiency in one of the skills of your choice",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                ]
            },
        ],
    },
    {
        id:"kor",
        name:"Kor ",
        variants:[
            {
                variantName:"Kor ",
                variantDescr:"An indigenous and independent race of Koris Strait, a mountanous piece of land under the formal control of city-state of Koris. This slender grey-skinned human-like race has a quite unique view towards life as a constant risk-reward struggle, making this race quite ambitious and competitive. \n \n Living in a dangerous environment and being surrounded by traitorous sierras have built their character and forged them into fearless and quite capable mountain climbers, warriors and adventurers. People of Kor can be often seen among mercenary troops and  law inforcement forces of the city of Koris. \n \n Tense at first, the relationship between the Koris and people of Kor became much warmer over centuries. City is able to provide some protection and trading opporunities, while Kor, in turn, deliver various important produce that their home, Valley of Kor, is able to grow, as well as capable fighters and mining expertise.",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to WIS, +1 to STR or DEX",
                            }
                        ]
                    },
                    {
                        featureName:"Mountain Born",
                        bullets:[
                            {
                                bullet:"You gain proficiency in Athletics and Acrobatics skills.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You do not require a climbing kit to climb. You also do not require to make an Athletics check to do so.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You acrobatic skills and experience in mountain climbing also taught you how to fall without causing a serious trauma. You receive no damage when falling up to 50 ft.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You are proficient with bows, staves and spears.",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                    {
                        featureName:"Piercing Sight",
                        bullets:[
                            {
                                bullet:"You gain darkvision (120ft)"
                            }
                        ]
                    },
                    {
                        featureName:"Kor Competitiveness",
                        bullets:[
                            {
                                bullet:"You gain Out of Turn ability:"
                            },
                            {
                                bullet:"Natural competitiveness of Kor is quite pronounced. You can attempt to swap your initiative order with an enemy that is within your melee range. Enemy’s initiative order has to be greater than yours, the size of the creature should not be larger than Large, and you are able swap the initiative only for next round of combat. To attempt the swap, you must take a contested Athletics or Acrobatics check with an opponent as your action. You can use this ability once per long rest as an action.",
                                isMultiLevel: "true",
                            }
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Korisian, Kor Signage"
                            }
                        ]
                    },
                    {
                        featureName:"Tools Proficiency",
                        bullets:[
                            {
                                bullet:"You are proficient with mason's tools."
                            }
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking & climbing - 40ft",
                            }
                        ]
                    },
                ],
            },
        ],
    },
    {
        id:"tieflings",
        name:"Tieflings ",
        variants:[
            {
                variantName:"Tieflings ",
                variantDescr:"Often mistaken as offsprings of devils and demons by uneducated folk, tieflings however are respected for their determinism and assertiveness, making them excellent lawyers, merchants and diplomats. Their origin goes back to the group of worshipers of Vigsla, Goddess of Pacts, that eventually made a mistake of denouncing her. \n \n Now, punished and locked in an eternal Pact, they themselves and their devil-like appearance are the ultimate examples of what may happen if one does not uphold their part of the bargain, especially when dealing with the god. ",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to CHA and WIS"
                            }
                        ]
                    },
                    {
                        featureName:"Careful Glance",
                        bullets:[
                            {
                                bullet:"You gain darkvision (60ft)"
                            }
                        ]
                    },
                    {
                        featureName:"Legacy of the Broken Promise",
                        bullets:[
                            {
                                bullet:"You gain free Firebolt, Control Flame and Produce Flame cantrips.  Those cantrips do not count towards known cantrips.",
                                isMultiLevel: "true",

                            },
                            {
                                bullet:"You gain a free 1st level Hellish Rebuke spell that you can use once per long rest as a reaction when you are being hit. This spell does not count towards known or prepared spells.",
                                isMultiLevel: "true",

                            },
                            {
                                bullet:"Select one cantrip or 2nd level spell from any class spell list. After each long rest, roll 1d20 to determine if you get access to this cantrip or not. Rolling 10 or more allows you to use this cantrip until the next long rest.",
                                isMultiLevel: "true",

                            },
                        ]
                    },
                    {
                        featureName:"Damage Resistances",
                        bullets:[
                            {
                                bullet:"You gain resistance to fire and psychic damage types."
                            }
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Korisian, Ignan"
                            }
                        ]
                    },
                    {
                        featureName:"Tools Proficiency",
                        bullets:[
                            {
                                bullet:"Caligrapher's supplies"
                            }
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 30ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Sepid Tieflings",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"You are a product of a frowned upon union of an aasimar and a tiefling. Your pale skin, almost devilish features, and constantly conflicting mix of two vastly different natures give you unnatural advantage... or it might as well be the source of your demise.",
                languages:"",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +1 to INT, you life expectancy is x2 of humans'"
                            }
                        ],
                    },
                    {
                        featureName:"Complicated Ancestry",
                        bullets:[
                            {
                                bullet:"You can choose between resistance to radiant damage or resistance to necrotic damage."
                            }
                        ],
                    },
                    {
                        featureName:"Hard to Read",
                        bullets:[
                            {
                                bullet:"Any Insight checks against you are done with disadvantage."
                            }
                        ],
                    },
                    {
                        featureName:"Internal Conflict",
                        bullets:[
                            {
                                bullet:"You gain a free Entropy Barrage spell. This spell does not count towards known or prepared spells. "
                            },
                            {
                                bullet:"Entropy Barrage: You create 3 magical darts, each automatically hits a random creature within 60 ft range. You or DM are free to choose the method for random target selection. Each dart has a chance to either deal 1d4 + Charisma modifier force damage or heal for 1d4 + Charisma modifier. Whether a dart deals damage or heals is determined by rolling 1d2 for each dart. All darts hit simultaneously. You gain additional 1d4 on level 5, 7 and 11. Evocation spell, takes 1 bonus action to cast, has instantaneous effect and it uses somatic component.",
                                isMultiLevel: "true",
                            },
                        ],
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Divinus"
                            }
                        ],
                    },
                ],
            },
        ],
    },
    {
        id:"warforged",
        name:"Warforged ",
        variants:[
            {
                variantName:"Warforged ",
                variantDescr:"You are a creation of stone and metal. Ages ago, you have been serving your purpose, and when the work was done,  you laid to rest. But now, you are awakened by unknown force with a new, self-defined purpose.",
                variantFeatures:[
                    {
                        featureName:"Ability Score Improvements",
                        bullets:[
                            {
                                bullet:"You gain +2 to CON"
                            },
                        ]
                    },
                    {
                        featureName:"Constructed Magnificence",
                        bullets:[
                            {
                                bullet:"You do not require to food, sleep or breathing. Magic cannot put you to sleep.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You gain immunity to diseases.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You gain darkvision (60ft)",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"You receive no damage when falling from up to 70 ft height. Fall damage that you receive by falling from up to 150 ft height is halved.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Your jumping height is 10 + your Strenght modifier",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                    {
                        featureName:"Sentry Rest",
                        bullets:[
                            {
                                bullet:"When you take a long rest, you must spend at least six hours in an motionless state, rather than sleeping. In this state, you appear inert, but you can see and hear as normal.",
                            },
                        ]
                    },
                    {
                        featureName:"Reanimation Diktat",
                        bullets:[
                            {
                                bullet:"When your hit points go down to zero, instead of fallign unconcious you get back on your feet with 1 hit point. You can use this ability once per long rest.",
                            },
                            {
                                bullet:"You gain advantage on death saving throws.",
                            },
                        ]
                    },
                    {
                        featureName:"Integrated Protection",
                        bullets:[
                            {
                                bullet:"Your base AC equals 12 + Dexterity modifier.",
                            },
                            {
                                bullet:"To put on and off an armor, you must incorporate (or deintegrate) it into your body over the course of a short rest. As long as you are alive, your armor cannot be removed from your body against your will.",
                            },
                        ]
                    },
                    {
                        featureName:"Languages",
                        bullets:[
                            {
                                bullet:"Korisian, Titan Speech",
                            }
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 30ft",
                            }
                        ]
                    },
                ],
            },
            {
                variantName:"Specialized Construct",
                variantWarning:"This is a variant race. Features and traits of the variant race are added to the base race.",
                variantDescr:"You previous life and purpose defined and shaped you, and it still serves your own goals quite well.",
                languages:[
                    {
                        language: "Korisian"
                    },
                    {
                        language: "Titan Speech"
                    },
                ],
                variantFeatures:[
                    {
                        featureName:"Custom Configuration",
                        bullets:[
                            {
                                bullet:"Select one of the following types:"
                            },
                            {
                                bullet:"Colossus: +1 to Strenght or Constitution, proficiency with martial weapons, your height is 7 ft minimum, you are able to carry very heavy objects.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Venator: +1 to Dexterity or Charisma, darkvision (60ft), you gain “Conceal Identity” ability - you are able to disguise yourself and switch between your true appearance and the disguise at will, but you are limited to a one permanent disguise option. Disguise cannot be percieved through upon close and physical inspection by other creatures other than other warforged.",
                                isMultiLevel: "true",
                            },
                            {
                                bullet:"Spellbearer:  +1 to Wisdom or Intelligence, you get 1 additional spell and 1 additional cantrip from any class spell list. Those are not counted towards known or prepared spells or cantrips.",
                                isMultiLevel: "true",
                            },
                        ]
                    },
                    {
                        featureName:"Speed",
                        bullets:[
                            {
                                bullet:"Walking - 35ft",
                            }
                        ]
                    },
                ],
            },
        ],
    },
];
