import './App.css';

function App({ children }) {

  
  return <div className="app">
    {children}
  </div>
}

export default App;
