export const getGodsData = () => [
    {
        id:"tim",
        name:"Timthirial",
        alias:"Clockmaster",
        domains:"Day & night cycle, light & darkness, beginning & end, sun & shadow, tradition & pre-determination",
        worship:"Majority of Hordarat, Church of the First Light, Creed of Sunbringer, Creed of Night Patron, aasimars",
        descr:"First of the Pantheon, Timthirial was the first deity to reach our to mortals of the Hordarat by shining his eternal light upon the world. Bringer and the guardian of light and shadow, creator of the Sun and numerous other stars across the Astral Sea, pushing back agains the chaos and terrors of the surrounding Void. The Clockmaster is the most popular deity among mortals, especially aasimars, descendands of the the most devoted first worshippers of the Sun.",
        planes:"Ringworld of Cadence",
        image: "gods/gods_tim.png"
    },
    {
        id:"vigsla",
        name:"Vigsla",
        alias:"Scrollbearer",
        domains:"Personal responsibility, agreements, balance, commerce & wealth, communication, marriage, Faustian bargain",
        worship:"Majority of tieflings, merchants, negotiators, judges",
        descr:"Second in rank only to Timthirial, Vigsla is a power broker between mortals and All-Pantheon. She is the mediator and negotiator on behalf of both sides, setting up various Pacts that allow gods to channel their energy through mortals, and for mortals to access divine powers. \n \n Vigsla also serves as an eternal patron of tieflings, descendands of worshippers who once opposed her. Cursed with demonic outlook and language only they can understand and learn, tieflings are forever bound to their goddess.",
        planes:"Concordant Skyways",
        image: "gods/gods_vigsla.png"
    },
    {
        id:"caertana",
        name:"Caertana",
        alias:"Tempers Rider",
        domains:"Justice & punishment, indifference, law & duty, retribution & revenge, thunder & storm, wraith",
        worship:"Lawyers, judges, law enforcement, seamen, maelstrom elves, victims of crime, vigilantes, maelstrom elves",
        descr:"[Description WIP]",
        planes:"Tidal Forum",
        image: "gods/gods_caertana.png"
    },
    {
        id:"juvena",
        name:"Juvena",
        alias:"Daredevil",
        domains:"Transition from life to death, transcendence & personal growth, constant change, wind & travel, lack of stability, thievery & murder, secrecy",
        worship:"Thieves, teachers, young people, arsonists, anarchists, navigators, guides, drow elves",
        descr:"[Description WIP]",
        planes:"Streams of Alteration",
        image: "gods/gods_juvena.png"
    },
    {
        id:"roja",
        name:"Roja",
        alias:"Reposer",
        domains:"Natural order of things, healing & restoration to former self, silence, death & peace of mind, ice & water, equiality, finality",
        worship:"Druidic circles, assassins, healers, graveyard workers, executioners, elves",
        descr:"[Description WIP]",
        planes:"Sempinternal Cenotaph",
        image: "gods/gods_roja.png"
    },
    {
        id:"tjon",
        name:"Tjon",
        alias:"Mauler / Hetaera",
        domains:"Mindless rage, ambition, war & domination, murder & violent death, fire & strength, lust, wild beasts",
        worship:"Military, Mayhem Cults, tattoo artists, courtesans, brothels",
        descr:"[Description WIP]",
        planes:"Gauntlet of Vehemence",
        image: "gods/gods_tjon.png"
    },
    {
        id:"fraed",
        name:"Fraedingur",
        alias:"Faceless",
        domains:"History & its preservation, public knowledge & learning, discovery & exploration, objectivity, music",
        worship:"Any place of public knowledge, public educators",
        descr:"[Description WIP]",
        planes:"Compendium Sphere",
        image: "gods/gods_fraed.png"
    },
    {
        id:"firmi",
        name:"Firmi",
        alias:"First Twin",
        domains:"Protection & steadfastness, order & duty, clear-mindedness, earth & stone, incorruptability, honor, stagnation, inability to change or care, emotionlessness, smithing & forging",
        worship:"Twin God Divinity, worship is conducted on a more personal level",
        descr:"[Description WIP]",
        planes:"Semiplanar Throne of Grit",
        image: "gods/gods_firmi.png"
    },
    {
        id:"aedis",
        name:"aedis",
        alias:"Second Twin",
        domains:"State & culture, loyalty & submission, perseverance of life, sustainability, sloth & laziness, existence without purpose, blind loyalty",
        worship:"Twin God Divinity, worship is conducted on a more personal level",
        descr:"[Description WIP]",
        planes:"Semiplanar Throne of Vigor",
        image: "gods/gods_aedis.png"
    },
];
