import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './History.css';
import { PUBLICURL } from '../data/const';

class History extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeIndex: "none",
    };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    this.setState({ 
      activeIndex: event
    })
  }

  
  render() {
    return (
      <>
        <div className="timeline">
          <div className="events">
            {
              this.props.data.map(t => 
              <div className={this.state.activeIndex === t.name ? "event-container-selected" : "event-container"} onClick={() => this.handleClick(t.name)} id={t.isAge ? "age" : ""}>
                <div className="event-name">{t.name}</div>
                <div className="event-date">{t.date}</div>
              </div> 
              )
            }
          </div>


          <div className="events-details"> 
          {
            this.state.activeIndex === "none" ?
            <div className="empty">
              <img src={PUBLICURL + "empty-states/empty-normal.png"} />
              Select item from the list
            </div> :
            this.props.data.filter(text => text.name===this.state.activeIndex).map(t => 
              <Fragment key={t.id}>
                <div className="details-name">{t.name}</div>
                <div className="history-content">
                  <div className="details-alias" id="alias-history">{t.date}</div>
                  <div className="details-descr">{t.details}</div>
                </div>

              </Fragment>)
          }
          </div>
        </div>
      </>
    );
  }
}

export default History;