export const getEventsData = () => [
    {
        name:"The Great Split & Cosmic Dawn",
        date:"Unknown",
        details:"[Description WIP]",
        isAge: true
    },
    {
        name:"Era of Conversion",
        date:"~100 000 000 - 50 000 BSF",
        details:"[Description WIP]",
        isAge: true
    },
    {
        name:"Creation of Aevintyr & First Children",
        date:"~1 200 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Creation of Balaur & Second Children",
        date:"~900 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Balaur Reprisal",
        date:"~700 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Creation of Kaeldom & Third Children",
        date:"~650 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Creation of Hordarat & Fourth Children",
        date:"~350 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Breach of Kaeldom",
        date:"~67 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Age of Dreamwalkers",
        date:"~50 000 - 12 000 BSF",
        details:"[Description WIP]",
        isAge: true
    },
    {
        name:"Prime Contact & Sunder of Vinculum",
        date:"~15 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"First Foundation of All-Pantheon & First Pact",
        date:"~12 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Age of Assent",
        date:"~12 000 - 7000 BSF",
        details:"[Description WIP]",
        isAge:true
    },
    {
        name:"Rise of Thanasimos Ascension",
        date:"~10 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Vigsla's Punishment & First Tieflings",
        date:"~9 300 BSF",
        details:"[Description WIP]"
    },
    {
        name:"First Encounters with Elves & Aasimars",
        date:"~9 500 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Fall of Thanas-City & Desolation of Rudr",
        date:"~7 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Age of Divine Silence",
        date:"~7 000 -  4 000 BSF",
        details:"[Description WIP]",
        isAge:true
    },
    {
        name:"Grand Displacement",
        date:"~6 900 - 5 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Formation of Western League & Houses of Trygg",
        date:"~6 300 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Arrival of Tjon",
        date:"~4 000 BSF",
        details:"[Description WIP]"
    },
    {
        name:"First Age of Tjon",
        date:"~4 000  - 3200 BSF",
        details:"[Description WIP]",
        isAge:true
    },
    {
        name:"Birth of Mayhem Cults",
        date:"~3 990 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Cruel Cycles",
        date:"~3 990 - 3370 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Rise of Renegades",
        date:"~3 350  - 3 230 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Arrival of Firmi & Aedis",
        date:"~3 230 BSF",
        details:"[Description WIP]"
    },
    {
        name:"First Age of Twin Gods",
        date:"~3 200 - 1800 BSF",
        details:"[Description WIP]",
        isAge:true
    },
    {
        name:"Grand Pacification & Return of Vigsla",
        date:"~3 000 - 2 700 BSF",
        details:"[Description WIP]"
    },
    {
        name:"First Sightings of Dwarves & Goliath Clans",
        date:"~2 300 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Hushed Years",
        date:"~2 300 - 3 BSF",
        details:"This age is characterized by a long awaited intermediate peace and of some degree of uncertainty. Memories and wounds from the Cruel Cycles were still reliatively fresh, and the world was anxiosly waiting for another conflict or vicious cycle of violence to continue.",
        isAge:true
    },
    {
        name:"Sunder of Vimerk",
        date:"~1 400 BSF",
        details:"[Description WIP]"
    },
    {
        name:"City-State of Koris Founded",
        date:"~1 200 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Alsaris Crusade",
        date:"~1 050 BSF",
        details:"[Description WIP]"
    },
    {
        name:"Age of Restoration",
        date:"0 ASF - Current",
        details:"[Description WIP]",
        isAge:true,
        asf: "true"
    },
    {
        name:"Second Foundation of All-Pantheon",
        date:"0 ASF",
        details:"[Description WIP]"
    },
    {
        name:"Unification of Eastern Marches",
        date:"1 345 ASF",
        details:"[Description WIP]"
    },
    {
        name:"War of Malediction",
        date:"1 834 ASF - Current",
        details:"Series of raids targeted to desolate northern part of Paleogos continent by Tryggs led to a rather hostile relationship with the elves of the Arsaria. Hostilities resulted in a direct war between Kindred Houses and Triumvirate of Arsaria. This war is also known as War of Malediction as it is still remain unclear what exactly triggered the conflict and why they are still fighting it with sever brutality. \n \n  Elven long lifespan, brutal advancement tactics and vast resources of their country were generally considered superior to the battle-hardened sailors of the North that lacks any significant sources of supply.  \n \n  But, to the surprise of the entire world, both sides managed to deal such a significat damage to each other in a very short amount of time, they almost immediately went into the stalemate. Originally filled with coastal cities, the entire northern coastline of the Paleogos region was turned into ashes, while only the cities of Faragas and Verssa remained standing. In the north, the central island of Trygg Gelida, the former home of the House Bodo was decimated and became unhabitable. \n \n  The stalemate has started not even a year into the conflict and it still lasts to this day. Conflict was additionally hindered by the vast distances between two countries and soared sea trade that the war was affecting. Any Trygg ships were ultimately banned from entering Arsarian ports, and Arsarian Armada to this day is still tracking and attacking any Trygg vessel that dares to swim across the Flaxed Sea and Caertana’s Dive. Meanwhile, Tryggs are responding similarly in kind in their own waters."
    },
    {
        name:"Lathagane Empire Founded",
        date:"1 645 ASF",
        details:"[Description WIP]"
    },
    {
        name:"Osilian Advance",
        date:"1 699 ASF",
        details:"[Description WIP]"
    },
    {
        name:"Fall of Galathar",
        date:"2 065 ASF",
        details:"[Description WIP]"
    },
    {
        name:"Koris Incursion",
        date:"2 099 ASF",
        details:"In 2099 ASF, a group of collaborators from Galathar attempted to assassinate the ruling Founding Familiies of Koris during their yearly public address to the Koris in the Grand Forum right at the heart of city. \n \n Using advanced alchemical equipment and poison, their plan was partially successful, as they wiped out majority of Rodas family, the Seamasters of Koris. The only surviving member of the family was Felix Rodas, current head of the family, while his wife Sanrevelle and sister Marianna were killed in the ensued chaos. Struck by some sort of alchemical bomb, both bodies were evaporated in the attack. \n \n Sicilus family, treasurers and negotiators of the Koris, and its head Marcus Sicilus was also personally affected, as his daughter Petra was poisoned and fell into coma-like state. She still presides in this state to this day.\n \n The attack left a long-lasting effect on the city, especially its marine operations as the city practically lost its renown family of admirals and sea navigators that were the  pillars of Koris' sea superiority. Galathar assassins has tainted city's reputation of a secure and a neutral safe haven for world trading, reducing the inflow of foreigners to its shores.\n \n Almost all assassins were either killed or captured, but it remains unknown how they've managed to infiltrate the city in the first place."
    },
    {
        name:"Current Year",
        date:"2 103 ASF",
        details:"[Description WIP]"
    },
    
    
];
