import React, { Fragment } from 'react';
import './Planes.css';
import { PUBLICURL } from '../data/const';
import Collapsable from '../Collapsable/Collapsable';

class Planes extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      showDetails: false,
      activeTabIndex:"inner"
    };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.state.showDetails ?
      this.setState({ 
        showDetails: false,
      }) : 
    this.setState({ 
      showDetails: true,
    }) 
  }
  handleTabClick(tab) {
    this.setState({ 
      activeTabIndex: tab
    })
  }

  render() {
    return (
      <>
        {
          this.state.showDetails ? 
            <div className="plane-map-modal"><img src={PUBLICURL + this.props.map} className="plane-map"/> </div> :
            <div className="plane-panel">
              <div className="party-tabs">
                <div className={this.state.activeTabIndex==="inner" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("inner")}>Inner Astral Sea</div>
                <div className={this.state.activeTabIndex==="outer" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("outer")}>Outer Astral Sea & Merged Planes</div>
                <div className={this.state.activeTabIndex==="edge" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("edge")}>Edge of the Void</div>
              </div>
              <div className="panel-content">
                {
                  this.props.data.filter(text => text.tabId===this.state.activeTabIndex).map(t =>
                    <div className="panel-img-wrapper"><img src={PUBLICURL + t.image} className="panel-img"/></div>
                  )
                }
                <div className="planes-list">
                  {
                    this.props.data.filter(text => text.tabId===this.state.activeTabIndex).map(t =>
                          <Fragment key={t.tabId}>
                            {
                              t.planes.map(ctn =>
                                <Collapsable name={ctn.name} subtitle={ctn.alias} descr={ctn.description} icon={PUBLICURL + ctn.icon} ></Collapsable>
                              )
                            }
                          </Fragment>
                        
                      )
                  }
                </div>
              </div>
            </div>
        }
        <div className='planes-buttons'>
          {this.state.showDetails ? <a href={PUBLICURL + "planes.png"} target={'_blank'} className="plane-toggle">Open in a separate tab</a> : <></>} 
          <div className="plane-toggle" onClick={() => this.handleClick()}>{this.state.showDetails ? "Close map" : "Show full map"}</div>
        </div>
      </>
    );
  }
}

export default Planes;