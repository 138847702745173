import './MainMenuItem.css';
import { NavLink } from "react-router-dom";

function MainMenuItem(props) {
  return (

    props.ext === "true" ?
      <a href={props.extURL} id={props.id} target="_blank">
        <img src={props.imagePath} />
        <div className="menu-item-text">{props.text}</div>
        <div className="back-deco"></div>
      </a>:
      <NavLink to={props.route} id={props.id}>
        <img src={props.imagePath} />
        <div className="menu-item-text">{props.text}</div>
        <div className="back-deco"></div>
      </NavLink>
  );
}

export default MainMenuItem;
