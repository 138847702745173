export const getEmpData = () => [
    {
        id:"vimerk",
        name:"Dwarven Republic of Vimerk ",
        alias:"rightful merits erudition",
        logo: "emp-logo/vimerk.png",
        keywords:[
            {
                key:"Isolationists",
            },
            {
                key:"Memorialists",
            },
            {
                key:"Divided",
            },
        ],
        details:[
            {
                history:"HIghly secretive society of mountain-born dwarves from north-eastern Hordarat, Vimerk was once a united nation living in a vast city expanded across the entire Nordadir region. Now only shards of once proud people scattered across the world. City believed to be having some traversal capabiilities, allowing for it to transport in its entirety to any place in the mortal realm. \n \n Centuries ago, an unknown phenomenon split the city into parts and spread them across the world, while leaving the central Vimerk in Nordadir, completely isolated from the rest of the Hordarat. Surrounded by dangerous anomalies, Vimerk was left on its own, and various expeditions initiated to learn its fate have failed miserably. ",
                goal:"Reduce the risk of losing world's knowledge by accumulating and keeping it secure from those unworthy. Reunite with the rest of the Enclaves and discover the ultimate fate of Vimerk.",
                capitalGovern:"A number of separated Dwarven Enclaves - Vimerk (completely isolated), Himeldan, Ankir (destroyed), Yeshir (destroyed), Varoth and Altakki. Representatives from each Enclave form a rudimentary republic that chooses a Primarch to lead them.",
                climate:"Derelict northern plains and plateaus filled with various ruins of ancient dwarven cities, almost forming some kind of agglomeration across Nordadir. \n \n   Numerous temporal and spacial magical anomalies that are constantly changing the outlook of the environment and reshaping the land.",
                lang:"Dwarven population outside of the Enclaves use regular Dwarvish. It is rumored that the older generations use some older Dwarvish dialect that is not yet known to the world.",
                religion:"Scarse records indicate that dwarves practice some form of ancestor worship, but vastly different from the one practiced by Western League. Dwarves possess a clear contempt towards Fraedingur and his worshipers due to their desire for public knowledge and freely available education. They do, however, acknowledge the Twin Gods due to their connection to the earth and rock. They often refer to the twins as  the “stone that is shaped by running water”.",
                military:"Unknown, but many encountered dwarves clearly have received some  combat training. Quite popular rumor states that all the dwarves outside of the Enclaves are members of the military branch of their society, and other dwarves simply never travel outside of the Enclaves.",
                economics:"Enclaves often engage in raw material trading (ore, gemstones), and in very rare cases they might share some technology and knowledge that are often vastly expensive. It is safe to assume that the dwarven economy is entirely internal and self-sustaining.",
                law:"Unknown. Historically, there are cases of witnessing some sort of dwarven “death squads” conducting public executions of various individuals across the world. While unpleasant, local governments often close their eyes on these situations and responding to it as “they probably had a good reason for it”.",
                magic:"Unknown source of power over stone and earth. Enclaves also possess a limited ability to transport the entire city across the world, but it often comes with some issues. ",

            },
        ]
    },
    {
        id:"koris",
        name:"City-state of Koris",
        alias:"mavericks of the free world",
        logo: "emp-logo/koris.png",
        keywords:[
            {
                key:"Independent",
            },
            {
                key:"Mercantile",
            },
            {
                key:"Essential",
            },
        ],
        details:[
            {
                history:"Founded almost 3000 year ago by Five Founding Families, the oligarchy of Koris serves as an extremely vital trading hub between the Western League, Lathagane Empire and Kingships of Osilius. \n \n Extremely high altitude and dangerous mountainpasses, as well as the only available sea route between Emergent Sea and Inner Waters in this part of the world dictate the necessity for those empires to cooperate with Koris. Of course, for the great benefit of the latter.",
                goal:"Maintain independence at any cost",
                capitalGovern:"Government is formed around Council of Founders, that includes current heads of each of the Founding Families (Laugens, Barthadors, Pentagars, Rodas and Sicilus), essentially forming an oligarhy.",
                climate:"Mountanous strait between two continents, high altitude, cold weather, various cave systems. Large amount of natural resources, but little farmable land in various hidden valleys in Koris Sierras. Some mountain passes are ravaged by the constant activity of rock dragons. Hallmark Woodlands is a unique self-sustaining ecosystem that has seeming an endless supply of high quality wood.",
                lang:"Korisian is the most common language in the northern hemisphere of Hordarat. High Korisian is used by members of the higher levels of korisian society, as well as among diplomats and other officials from other realms.",
                religion:"While korisians follow All-Pantheon and recognizing Timthirial as its leader, at some point Laugen family, one of the founders of Koris, established their own religion of the sun god, Church of the First Light. This following equally recognizes both light and dark domains Timthirial controls, approaching the worship on a more fundamental and generic level. \n \n Since then, aasimars of Besirtha Domain (mainly following their own Creed of the Sunbringer) became at odds with the Koris, mainly using their diplomatic relationship for vigorous religious debates. Is it rumored that aasimars are not keen on the idea of less zealous and more welcoming worship of their god that draws power from such worship, hence they are trying to reduce the influence of the Church as much as they can. \n \n Some may argue that the Church is just another example of Founders' ambition to maintain own independence.",
                military:"Korisian forces focus on smaller tactics and agility. There is no mandatory service, and a lot of focus is put on mercenaries. \n \n Koris is famous for its powerful trade fleet that protects Gates of Elidur, the only sea route that connects Emergent Sea and Inner Waters, allowing Lathagane Empire and Western league to engage in world trade. \n \n Korisian Javelins are another popular topic, as this policing organization dates centuries back to the original mercenary group of Kor fighters that were protecting the entire region during various dark periods of history.",
                economics:"General trade, high quality craftsmanship, trade routes protection, mercenary export, silver mining. Osmium mining and producing own platinum-esque coinage, harvesting high quality wood from Hallmark Wildlands.",
                law:"Death penalty for fraud and illegal trading of osmium.",
                magic:"Magic is mostly legal, but certain practicies are frowned upon. Necromancy, resurrection magic and diamond markets are strictly regulated. Magic can be obtained through Divine Pacts, it requires approval from the local clergy, but it is still accessible.",
            },
        ]
    },
    {
        id:"league",
        name:"Western League ",
        alias:"Beyond blood",
        logo: "emp-logo/league.png",
        keywords:[
            {
                key:"Ancestral",
            },
            {
                key:"Redemptive",
            },
            {
                key:"Self-reliant",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",

            },
        ]
    },
    {
        id:"lathagane",
        name:"Lathagane Empire",
        alias:"",
        logo: "emp-logo/lathagane.png",
        keywords:[
            {
                key:"Resourceful",
            },
            {
                key:"Unrelenting",
            },
            {
                key:"Expansionists",
            },
        ],
        details:[
            {
                history:"A relatively young state that originates from the colonists of the Ortus, or Origin Valley, descendats of the first Trygg islanders that settled in the northern part of the Osilius continent. At some point, these settlers grew tired of harrasment from the other kingdoms on the continent that saw them as vermin and parasites. Various minor conflicts, lack of resources and non-existent support from their bretheren in the north - all of it have shaped these Trygg settlers into hardened, experienced and very resourseful tacticians.  \n \n After preparing in secret for several years, they've started to expand at exceptional rate, claiming entire kingdoms and rasing them to the literal dust within years or even months. Over time, such approach and growing defiance gave birth to the Empire that we see now. \n \n Practicing a very straightforward atitude towards diplomacy and war, Lathagane Empire has a varying relationships with the other countries. Perpetual war with Kingships of Osilius over the centuries became a somewhat ordinary course of events to the Empire, henceforth informing their approach to international relationships with other realms.",
                goal:"Conquer the entirety of Osilius continent. Integrate or eradicate the remaining kingdoms that have been and still resisting the empire's advancement for centuries. Become completely independent and separated from the influence of Houses of Trygg.",
                capitalGovern:"The capital of the empire, Fenavar, City of Bastions, is the embodiment of protection and defense. This is the most fortified city in the world, housing hundreds of fortresses and castles that essentially form various city districs. \n \n Empire is governed by two Emperors. Warmaster Emperor's role is to tackle international relationships and to advance various mimiltary campaigns against other realms on the continent. Lawspeaker Emperor is focused on internal affairs of the Empire.",
                climate:"Very diverse landscape that provides a lot of natural resources. Continental climate with lush forests and tundras. Eastern part ofthe empire is rather desolate from the war with various kingdoms of Osilius.",
                lang:"Osilian, mostly originated from Klaki, speech of the Houses of Trygg, and mixed with the local languages of the Osilius continent. Korisian is also used, but mostly for communication with foreigners. Empire encourages its denizens to learn new langauges and integrate other speeches into Osilian, making it more and more dominant in this part of the world.",
                religion:"Like the rest of the world, Empire worships All-Pantheon, but focuses more on certain traits and domains of each god. In addition to that, Ortus Statism started to become a religion of its own, where the actual object of veneration is the state and Emperors themselves. \n \n Patriotism is a big part of the culture, but it is not as overzealous as e.g. aasmiars from Besithra Domain. Empire teaches its subjects to be pragmatic, persistent, thoughtful and effective, which further solidifes the belief in the government and Empire's way of life.",
                military:"Massive military, very diverse and specialized, famous for heavy infrantry and siege engineering. Empire has a somewhat bad reputation for their rather brutish approach to siege tactics and usage of war machines, often leaving nothing but ruble that they quickly build over with their own settlements. Big focus on discipline, but at the same time allowing for a broader individual initiative and freedoms than most of other armies in the world (with the exception of elves).",
                economics:"Weapon and armor export, engineering expertise, general trade. Very close economic relationship with Tryggs in the north. Slavery is banned, but inderntured servitude is one of the options for prisoners of war and criminals.",
                law:"Nepotism is highly discouraged, because high rank comes with a responsibility that the person needs to uphold. In the military, the drug use is allowed as long as it does not prevent soldiers from performing their duties.",
                magic:"Legal, but certain practicies are banned, e.g. spirit mediation and nature magic, hence most of the druidic cirlces are almost forbidden unless those circles are participating in war (willingly or not). Ban of the nature magic and spirituality is motivated by the belief that citizens of the empire are self-reliant and persistent enough to dominate over not only men, but also over the nature, history and outside forces - not the other way around. Divine Pacts are very strict and no easily available for majority of population, unless they join the military.",

            },
        ]
    },
    {
        id:"trygg",
        name:"Kindred Houses of Trygg ",
        alias:"Lance through ice",
        logo: "emp-logo/trygg.png",
        keywords:[
            {
                key:"Close-knit",
            },
            {
                key:"Masters of the sea",
            },
            {
                key:"Traditional",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",

            },
        ]
    },
    {
        id:"arsaria",
        name:"Triumvirate of Arsaria ",
        alias:"We will return",
        logo: "emp-logo/arsaria.png",
        keywords:[
            {
                key:"Secluded",
            },
            {
                key:"Immortal",
            },
            {
                key:"Vengeful",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",

            },
        ]
    },
    {
        id:"rethris",
        name:"Hive Cities of Rethris Expanse ",
        alias:"Fuck off",
        logo: "emp-logo/rethris.png",
        keywords:[
            {
                key:"Free",
            },
            {
                key:"Diverse",
            },
            {
                key:"Defiant",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",

            },
        ]
    },
    {
        id:"skatitai",
        name:"Kinfolk of Skatitai ",
        alias:"Ascend the Apex",
        logo: "emp-logo/skatitai.png",
        keywords:[
            {
                key:"Competitive",
            },
            {
                key:"Familial",
            },
            {
                key:"Virtuous",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",

            },
        ]
    },
    {
        id:"osilius",
        name:"Kingships of Osilius ",
        alias:"persist or perish",
        logo: "emp-logo/osilius.png",
        keywords:[
            {
                key:"Desperate",
            },
            {
                key:"Survivalists",
            },
            {
                key:"Begrudging",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",
            },
        ]
    },
    {
        id:"marches",
        name:"Eastern Marches ",
        alias:"",
        logo: "emp-logo/marches.png",
        keywords:[
            {
                key:"Cooperative",
            },
            {
                key:"Factional",
            },
            {
                key:"Powerplayers",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",
            },
        ]
    },
    {
        id:"besithra",
        name:"Besithra Domain ",
        alias:"",
        logo: "emp-logo/besithra.png",
        keywords:[
            {
                key:"Zealous",
            },
            {
                key:"Conservative",
            },
            {
                key:"Exalted",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",
            },
        ]
    },
    {
        id:"nomads",
        name:"Goliath Nomads",
        alias:"",
        logo: "",
        keywords:[
            {
                key:"-",
            },
            {
                key:"-",
            },
            {
                key:"-",
            },
        ],
        details:[
            {
                history:"",
                goal:"",
                capitalGovern:"",
                climate:"",
                lang:"",
                religion:"",
                military:"",
                economics:"",
                law:"",
                magic:"",
            },
        ]
    },
    
];
