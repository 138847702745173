import './MapsMenu.css';
import { PUBLICURL } from '../data/const';
import React, { Fragment } from 'react';
import MainMenuItem from '../MainMenu/MainMenuItem';


class MapsMenu extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeTabIndex:"worlds"
    };

  }

  handleTabClick(tab) {
    this.setState({ 
      activeTabIndex: tab
    })
  }
  render() {
    return (
      <div className="maps-menu">
        <div className="party-tabs">
          <div className={this.state.activeTabIndex==="worlds" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("worlds")}>Worlds</div>
          <div className={this.state.activeTabIndex==="regions" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("regions")}>Regions</div>
          <div className={this.state.activeTabIndex==="settlements" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("settlements")}>Settlements</div>
          <div className={this.state.activeTabIndex==="other" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("other")}>Other</div>
          <div className={this.state.activeTabIndex==="godspaces" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("godspaces")}>God-Spaces</div>
        </div>
        <div className="maps-listing">
          {
            this.state.activeTabIndex === "worlds" ? 
            <>
              <MainMenuItem ext="true" extURL={PUBLICURL+"maps/world.png"} text="Hordarat" id="world" imagePath={PUBLICURL + "maps/icons/worlds/hordarat.png"}/> 
              <MainMenuItem route="/" text="Vessen" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/vessen.png"}/> 
              <MainMenuItem route="/" text="Aevintyr" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/aevintyr.png"}/> 
              <MainMenuItem route="/" text="Kaeldom" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/kaeldom.png"}/> 
              <MainMenuItem route="/" text="Balaur" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/balaur.png"}/> 
              <MainMenuItem route="/" text="Pagonia of Convalescence" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/pagonia.png"}/> 
              <MainMenuItem route="/" text="Plains of Indemnity" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/wip.png"}/> 
              <MainMenuItem route="/" text="Null-Eye" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/nulleye.png"}/> 
              <MainMenuItem route="/" text="The Void" id="unknown" imagePath={PUBLICURL + "maps/icons/worlds/wip.png"}/> 
            </> : 
            this.state.activeTabIndex === "regions" ? 
            <>
              <MainMenuItem ext="true" extURL={PUBLICURL+"maps/koris_region.png"} text="Koris Strait" id="koris-region" imagePath={PUBLICURL + "maps/icons/regions/koris-strait.png"}/>
              <MainMenuItem route="/" text="Ekithir | Dunes of Exeris" id="unknown" imagePath={PUBLICURL + "maps/icons/regions/ekithir.png"}/> 
              <MainMenuItem route="/" text="Osilius" id="unknown" imagePath={PUBLICURL + "maps/icons/regions/osilius.png"}/> 
            </> : 
            this.state.activeTabIndex === "settlements" ? 
              <>
                <MainMenuItem ext="true" extURL={PUBLICURL+"maps/himeldan_city.png"} text="Himeldan" id="himeldan" imagePath={PUBLICURL + "maps/icons/settlements/himeldan-city.png"}/> 
                <MainMenuItem ext="true" extURL={PUBLICURL+"maps/koris_city.png"} text="Koris" id="koris-city" imagePath={PUBLICURL + "maps/icons/settlements/koris-city.png"}/>
                <MainMenuItem ext="true" extURL={PUBLICURL+"maps/jork_city.png"} text="Jork" id="koris-jork" imagePath={PUBLICURL + "maps/icons/settlements/jork-city.png"}/> 
                <MainMenuItem route="/" text="Galathar" id="unknown" imagePath={PUBLICURL + "maps/icons/settlements/galathar.png"}/> 
                <MainMenuItem route="/" text="Krosvud" id="unknown" imagePath={PUBLICURL + "maps/icons/settlements/krosvud.png"}/> 
                <MainMenuItem route="/" text="The Spike" id="unknown" imagePath={PUBLICURL + "maps/icons/settlements/spike.png"}/> 
                <MainMenuItem route="/" text="Skatitai" id="unknown" imagePath={PUBLICURL + "maps/icons/settlements/skatitai.png"}/> 

              </> : 
            this.state.activeTabIndex === "other" ? 
              <>
                <MainMenuItem ext="true" extURL={PUBLICURL+"maps/megalith_bastion_other.png"} text="Himeldan - Megalith Bastion" id="megalith" imagePath={PUBLICURL + "maps/icons/settlements/himeldan-city.png"}/> 
                <MainMenuItem ext="true" extURL={PUBLICURL+"maps/brass_cascade.png"} text="Do not use yet" id="brass-cascade" imagePath={PUBLICURL + "maps/icons/settlements/himeldan-city.png"}/> 
              </> : 
            this.state.activeTabIndex === "godspaces" ? 
              <>
                <MainMenuItem route="/" text="Compendium Sphere" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/wip.png"}/> 
                <MainMenuItem route="/" text="Concordant Skyways" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/vigsla.png"}/> 
                <MainMenuItem route="/" text="Gauntlet of Vehemence" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/tjon.png"}/> 
                <MainMenuItem route="/" text="Lethargic Veldt" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/wip.png"}/> 
                <MainMenuItem route="/" text="Ringworld of Cadence" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/tim.png"}/> 
                <MainMenuItem route="/" text="Semiplanar Throne of Vigor" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/firmi.png"}/> 
                <MainMenuItem route="/" text="Semiplanar Throne of Grit" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/aedis.png"}/> 
                <MainMenuItem route="/" text="Sempinternal Cenotaph" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/roja.png"}/> 
                <MainMenuItem route="/" text="Streams of Alteration" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/wip.png"}/> 
                <MainMenuItem route="/" text="Tidal Forum" id="unknown" imagePath={PUBLICURL + "maps/icons/godspaces/wip.png"}/> 
              </> : <></> 
          }
        </div>
        


      </div>
    );
  }
}

export default MapsMenu;