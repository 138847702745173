import React, { Fragment } from 'react';
import './About.css';
import { PUBLICURL } from '../data/const';

class About extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeDotIndex:"first"
    };
    this.handleDotClick = this.handleDotClick.bind(this)
  }

  handleDotClick(dot) {
    this.setState({ 
      activeDotIndex: dot
    })
  }

  render() {
    return (
      <div className="about-container">
        {
          this.props.data.filter(i=> i.id === this.state.activeDotIndex).map(s =>
            <div className="about-slide" key={s.id}>
              {
                s.blocks.map(f =>
                  <div className="about-slide-block">{f.text}</div>
                )
              }
            </div>
          )
        }
        <div className="about-slider-dots">
          <div className={this.state.activeDotIndex === "first" ? "slider-dot-active" : "slider-dot"} onClick={()=> this.handleDotClick("first")}> </div>
          <div className={this.state.activeDotIndex === "second" ? "slider-dot-active" : "slider-dot"} onClick={()=> this.handleDotClick("second")}> </div>
          <div className={this.state.activeDotIndex === "third" ? "slider-dot-active" : "slider-dot"} onClick={()=> this.handleDotClick("third")}> </div>
        </div>
      </div>
    );
  }
}

export default About;