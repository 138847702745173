import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './Languages.css';
import { PUBLICURL } from '../data/const';
import Collapsable from '../Collapsable/Collapsable';

class Languages extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeIndex: "none",
      show:false
    };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(event) {
    this.setState({ 
      activeIndex: event
    })
  }

  render() {
    return (
      <>
        <div className="languages">
          <div className="languages-list">
            {
              this.props.data.map(t => 
              <div className={this.state.activeIndex===t.name ? "languages-container-active" : "languages-container"} onClick={() => this.handleClick(t.name)}>
                <div className="languages-name">{t.name}</div>
              </div> 
              )
            }
          </div>


          <div className="languages-details"> 
          {
            this.state.activeIndex === "none" ?
            <div className="empty">
              <img src={PUBLICURL + "empty-states/empty-normal.png"} />
              Select item from the list
            </div> :
            this.props.data.filter(text => text.name===this.state.activeIndex).map(t => 
              <>
                <div className="details-name">{t.name}</div>
                <div className="languages-content" key={t.id}>
                  {
                    t.details.map(g =>
                      <Collapsable name={g.lang} subtitle={g.type} descr={g.descr} />

                    )
                  }
                </div>
              </>
             ) 
          }
          </div>
        </div>
      </>
    );
  }
}

export default Languages;