import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './Collapsable.css';
import { PUBLICURL } from '../data/const';

class Collapsable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isExpanded: this.props.defaultExpanded,
    };
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.state.isExpanded ? 
      this.setState({ 
        isExpanded: false
      }) : 
        this.setState({ 
          isExpanded: true
        })
  }

  render() {
    return (
      <>
        <div className="collapsable-container" id={this.props.id}>
          <div className={this.props.subtitle ? this.props.icon ? "collapsable-clickable-rich-with-icon" : "collapsable-clickable-rich" : "collapsable-clickable-simple"}>
            {
              this.props.icon ? <img className="collapsable-icon" src={this.props.icon}/> : <></>            
            }
            <div className="clues-bullet-name">{this.props.name}</div>
            <div className={this.props.subtitle ? "clues-bullet-subtitle" : <></>}>{this.props.subtitle}</div>
            <img className={this.state.isExpanded ? "collapsable-arrow-expanded" : "collapsable-arrow"} src={PUBLICURL + "arrow-right.png"} onClick={() => this.handleClick()} />
          </div>
          {
            this.state.isExpanded ? 
            this.props.type === "list" ? this.props.children : this.props.icon ? <div className="clues-bullet-descr-rich-with-icon">{this.props.descr}</div> : <div className="clues-bullet-descr-collapsable">{this.props.descr}</div> : <></>
          }
        </div>
      </>
    );
  }
}

export default Collapsable;