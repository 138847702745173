import './Loading.css';
import { PUBLICURL } from '../data/const';
import React, { useState, useEffect } from 'react';


const Loading = ({ children }) => {

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  return (
    <>
      {
        loading ? 
          <div className="loading">
            <img src={PUBLICURL + "intro/lines.png"} id="intro" />
            <img src={PUBLICURL + "intro/cloud.png"} id="cloud" />
          </div> :       
            children
      }
    </>
  );

}

export default Loading;
