import './Party.css';
import { PUBLICURL } from '../data/const';
import React, { Fragment } from 'react';
import Collapsable from '../Collapsable/Collapsable';


class Party extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeTabIndex:"schedule",
      activeTabIndexMain: "quests"
    };

  }

  handleTabClick(tab) {
    this.setState({ 
      activeTabIndex: tab
    })
  }
  handleTabClickMain(tab) {
    this.setState({ 
      activeTabIndexMain: tab
    })
  }
  
  render() {
    return (
      <>
        <div className="party">
          <div className="quests">
          <div className="party-tabs">
            <div className={this.state.activeTabIndexMain ==="quests" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClickMain("quests")}>Active quests</div>
            <div className={this.state.activeTabIndexMain ==="deeds" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClickMain("deeds")}>Deeds</div>
          </div>
          <div className="party-content">
              {
                this.state.activeTabIndexMain==="quests" ?
                  <div className="quest-list">
                  {
                    this.props.questData.map(t => 
                      <div className="quest-container">
                          <img className="quest-marker" src={t.type === "hooks" ? PUBLICURL + "hook_marker.png" : t.type === "secondary" ? PUBLICURL + "quest_marker_secondary.png" :PUBLICURL + "quest_marker.png"} />
                          <div className="quest-name">{t.name}</div>
                          <div className="quest-participants">{t.participants}</div>
                          <div className="quest-objective">{t.objective}</div>
                      </div>
                    )
                  }
                  </div> : 
                    this.state.activeTabIndexMain==="deeds" ?
                    this.props.deedsData.map(cl =>
                      <div className="party-recap">
                        <div className="clues-bullet-subtitle" id="subtitle-recap">{cl.lastUpdated}</div>
                        <Collapsable name="Campaign" subtitle="General info about campaign" descr={cl.players}/>
                        <Collapsable name="Deeds" subtitle="List of major achievements" descr={cl.deeds} />
                        <Collapsable name="Kills" subtitle="List of notable kills" descr={cl.list} />
                        <Collapsable name="Deaths & healing" subtitle="Occurred party deaths and other things" descr={cl.deaths}/>
                        <Collapsable name="Divine & arcane" subtitle="Otherworldy encounters" descr={cl.divinearcane}/>
                        <Collapsable name="Exploration" subtitle="Things explored & found" descr={cl.exploration}/>
                        <Collapsable name="Lucky rolls" subtitle="Graces of the dice" descr={cl.rolls}/>
                        <Collapsable name="Jobs" subtitle="List of completed jobs & contracts" descr={cl.jobs}/>
                        <Collapsable name="Misdemeanours" subtitle="Various criminal activity" descr={cl.crime}/>
                        <Collapsable name="Naughty feats" subtitle="Woohoo" descr={cl.seks}/>
                        <Collapsable name="Nutrition facts" subtitle="All things foods and drinks" descr={cl.food}/>
                        <Collapsable name="Other" subtitle="Things that don't fit other categories" descr={cl.other}/>
                        <Collapsable name="Risky dealings" subtitle="Sometimes stupid things happen" descr={cl.risky}/>
                        <Collapsable name="Pets" subtitle="Pet-related stats" descr={cl.pets}/>
                        <Collapsable name="Timeline" subtitle="Spent days and other counters" descr={cl.time}/>
                      </div>
                  ) : <></>

              }</div>
          </div>
          <div className="clues-contacts">
              <div className="party-tabs">
                <div className={this.state.activeTabIndex==="schedule" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("schedule")}>Recap</div>
                <div className={this.state.activeTabIndex==="notes" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("notes")}>Notes</div>
                <div className={this.state.activeTabIndex==="clues" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("clues")}>Items</div>
                <div className={this.state.activeTabIndex==="contacts" ? "party-tab-active" : "party-tab"} onClick={() => this.handleTabClick("contacts")}>Contacts</div>
              </div>
              <div className="party-content">
                {
                  this.state.activeTabIndex==="recap" ? 
                    this.props.recapData.map(cl =>
                      <div className="party-recap">
                        <div className="clues-bullet-subtitle" id="subtitle-recap">{cl.lastUpdate}</div>
                        <div className="clues-container" id="text-recap" >
                          <div className="clues-bullet-descr">
                            {cl.description}  
                          </div>
                        </div>
                      </div>
                  ):
                      this.state.activeTabIndex==="contacts" ? 
                      this.props.contactsData.map(cl =>
                          <>
                          {
                            cl.bullets.sort((a, b) => a.name.localeCompare(b.name)).map(clb =>
                              <Collapsable name={clb.name} subtitle={clb.location} descr={clb.descr} icon={clb.gender==="m" ? PUBLICURL + "empty-states/gender-male.png" : clb.gender==="f" ? PUBLICURL + "empty-states/gender-female.png" : clb.gender==="mf" ? PUBLICURL + "empty-states/gender-mf.png" : clb.gender==="mm" ? PUBLICURL + "empty-states/gender-mm.png" : clb.gender==="grp" ? PUBLICURL + "empty-states/gender-grp.png" : clb.gender==="demon" ? PUBLICURL + "empty-states/gender-demon.png" : <></>}/>
                            )
                          }
                          </>
                      ):
                        this.state.activeTabIndex==="clues" ? 
                        this.props.cluesData.map(cl =>
                          <>
                          {
                            cl.bullets.sort((a, b) => a.name.localeCompare(b.name)).map(clb =>
                              <Collapsable name={clb.name} subtitle={clb.type} descr={clb.descr} icon={PUBLICURL + "empty-states/scroll-item.png"}/>
                            )
                          }
                          </>
                        ):
                          this.state.activeTabIndex==="notes" ? 
                          this.props.notesData.map(cl =>
                            <>
                            {
                              cl.bullets.sort((a, b) => a.name.localeCompare(b.name)).map(clb =>
                                <Collapsable name={clb.name} subtitle={clb.type} descr={clb.descr} icon={clb.type === "History" ? PUBLICURL + "empty-states/scroll-history.png" : clb.type === "Nature" ? PUBLICURL + "empty-states/scroll-nature.png" : clb.type === "Organization" ? PUBLICURL + "empty-states/scroll-org.png":<></>}/>
                              )
                            }
                            </>
                          ):
                          this.state.activeTabIndex==="schedule" ? 
                          
                          this.props.scheduleData.map(cl =>
                            <div className="party-recap">
                              
                              <div className="schedule-block" id="next-game">
                                <div className="details-keyword">
                                  RECAP OF GAME #17
                                </div>
                              </div>
                              {
                                this.props.recapData.map(cl =>
                                  <>
                                  
                                    
                                    <div className="clues-container" id="text-recap" >
                                      <div className="clues-bullet-descr">
                                      <div className="party-status">
                                        <img className="ps-icon" src={PUBLICURL+ "party/party-aedis.png"} id="ps-aedis"/>
                                        <img className="ps-icon" src={PUBLICURL+ "party/party-bronx.png"} id="ps-bronx"/>
                                        <img className="ps-icon" src={PUBLICURL+ "party/party-firmi.png"} id="ps-firmi"/>
                                        <img className="ps-icon" src={PUBLICURL+ "party/party-samar.png"} id="ps-samar"/>
                                        <div className="ps-icon-status-bad">
                                          <img className="ps-icon" src={PUBLICURL+ "party/party-sonya.png"} id="ps-sonya"/>
                                          <img src={PUBLICURL + "party/strike.png"} id="intro-ps" />
                                        </div>
                                        
                                      </div>
                                      <div className="clues-bullet-subtitle" id="subtitle-recap">{cl.lastUpdate}</div>
                                        <div style={{width:"100%",justifySelf:"center", display:"flex", justifyContent:"center"}}>
                                          <img src={PUBLICURL + "party/himeldan-recap.png"} style={{height:"200px",justifySelf:"center"}}/>
                                        </div>
                                        <br/>
                                        {cl.description}
                                        <br/>
                                        Session notes
                                        <br/>
                                        <br/>
                                        <Collapsable name="Bronx - searching for clues in Himeldan" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">A warforged tomb with a broken bagpipe was found underneath The Spike, ancient observatory complex in Koris Sierras, northwest of Koris. The place was raided by Mayhem Cults fanatics several years ago, seemingly targeting the tomb itself. The Spike was abandoned since then and not considered operational. <br/><br/>Mayhem Cults is a collective term to describe numerous cults that worship Tjon, Goddess of Havoc, specficilally more violent aspect of her divinity.</div>
                                        </Collapsable>
                                        <Collapsable name="Darius Fers - interrogation notes" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">Darius tried ot save Patria after she was locked away by Ixion and his Cabal. Darius did not betray her, he simply got in a way.<br/><br/> Patria entered a consipracy with Ixion prior to her imprisonment. Whatever deal they've made led to the burst of magical power across the entire Thanasimos Ascension, which eventually led to chaos. It appears that Ixion betrayed Patria soon after this. His Cabal of Seclusion was his solution to hide Patria and the nature of their deal from the rest of the civilization.<br/><br/> Old Covenant is a group of similarly cursed members of Ascension, trying to reach Thanas-Axis, the capital of the Ascension and find Patria to break the curse. They have the means of finding other members across generations and cycles of the cursed reincarnation.<br/><br/>Trevani were Divine Speachmakers under supervision of Darius, they were the voice of the gods having a direct line of communciation with the divine. Even the gods noticed something is wrong after Ixion and Patria's deal.<br/><br/> Bronx is a warforged, part of "peacekeeping forces" back in a day, eradicating dangerous when the world turned to shit after gods went silent.<br/><br/>Pale trees are a divine phenomenon. A white tree stricken by lightning caused by the Caerana, Goddess of Judgement herself. Darius had a map with locations of such trees hidden in one of his past live's home in Koris.</div>
                                        </Collapsable>
                                        <Collapsable name="Response to Firmi's Sending" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">The response from Patria was rather chaotic, but at the same time understandable to Firmi: <br/><br/> "LIVE, IXION! LIVE AND REMEMBER UNTIL THE STARS FADE AWAY!.. FILL YOUR EXISTENCE WITH ETERNAL BITTERNESS TO RIVAL YOUR VILENESS!.."</div>
                                        </Collapsable>
                                        <br/>
                                        New contacts
                                        <br/>
                                        <br/>
                                        <Collapsable name="Azal Rafis" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">Ambassador of Western League. He invited your party over to Koris to discuss the fate of late Kalim Araki, who happened to be one of close contacts of Azal. Curretly resides in Amber Alcazar, Western League empassy complex in Koris.</div>
                                        </Collapsable>
                                        <Collapsable name='Pajima "PJ" Giggle-Itz' type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">One of the curators of Atheneum, grand library of Himeldan. She helped Bronx with his personal queries. She joins your party as you discovered a hidden entrance to the area beneath Atheneum.</div>
                                        </Collapsable>

                                        <br/>
                                        New items
                                        <br/>
                                        <br/>
                                        <Collapsable name="Broken mechanical bagpipe / Bronx" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">Bagpipe that belongs to a fallen warforged found underneath The Spike. There is a name written on it - Brook.</div>
                                        </Collapsable>
                                        <Collapsable name="Ceremonial mask / Party" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">Ceremonial mask with some bits written in Thanasian on the insides that can be roughly translated as follows: “Her forgiveness is a bliss , his ambition is a sorrow... Ixion be damned... Cabal shattered the Ascension, but Old Covenant reshaped us... We will not return as prior selves”. <br/><br/>Sonya found this item in Jork.</div>
                                        </Collapsable>
                                        <Collapsable name="Dwarven cookbook / Bronx" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">A small (for Bronx) pocket book containing various recipies written in Dwarvish. Gifted to Bronx by Pajima, who also attached translation notes in Korisian. <br/><br/>Book looks rather used, but well-kept and with renovated cover. Pages has a strong smell of various cooked meats and black pepper. Perhaps this books was owned by someone not very concerned about their diet.</div>
                                        </Collapsable>
                                        <Collapsable name="Hemo-amplifier / Party" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">A metalic bracer that has a set of spiralled blades on the inside. Ring around the wrist rotates clock-wise. Rotating the ring will expose the blades, slashing the forearm of the owner and contracting back, while absorbing the blood from the wound and immediately catharizing in. Sonya found this item in Jork.</div>
                                        </Collapsable>
                                        <Collapsable name="Peppa' / Bronx" type="list" id="collapsable-basic">
                                            <div className="clues-bullet-descr-list">Dragon breath-infused spice. Grants 1d10 temporary hit points when consumed. Bag contains 5 portions. Keeps the food warm for a significant amount of time. Great addition for a mulled wine.</div>
                                        </Collapsable>
                                      </div>
                                    </div>
                                      
                                  </>
                                )
                              }

                            </div>
                        ): <></>
                }
              </div>
          </div>
        </div>
      </>
    );
  }
}

export default Party;