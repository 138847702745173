import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './App.css';
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import MainMenu from './MainMenu/MainMenu';
import MapsMenu from './Maps/MapsMenu';
import MainMenuItem from './MainMenu/MainMenuItem';
import LoreMenu from './Lore/LoreMenu';
import GodsMenu from './Gods/GodsMenu';
import { NavLink } from "react-router-dom";
import RacesMenu from './Races/RacesMenu';
import Planes from './Planes/Planes';
import EmpiresMenu from './Empires/Empires';
import History from './History/History';
import Languages from './Languages/Languages';
import Loading from './Loading/Loading';
import { getGodsData } from "./data/GodsData";
import { getEventsData } from "./data/EventsData";
import { getRacesData } from "./data/RacesData";
import { getLangData } from "./data/LangData";
import { getQuestsData } from "./data/QuestsData";
import { getEmpData } from "./data/EmpireData";
import { PUBLICURL } from './data/const';
import Party from './Party/Party';
import { getPartyStatusData, getRecapData } from './data/RecapData';
import { getCluesData, getContactsData, getNotesData } from './data/CluesContactData';
import { getPlanesData } from './data/PlanesData';
import About from './About/About';
import Collapsable from './Collapsable/Collapsable';
import { getAboutData } from './data/AboutData';
import { getAppearanceData } from './data/AppearanceData';
import { getScheduleData } from './data/ScheduleData';
import { getDeedsData } from './data/DeedsData';

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createHashRouter([
  {
    path: "/",
    element: 
    <App>
      <img src={PUBLICURL + "home/top-deco.png"} className="main-page-logo"/>
      <MainMenu>
        <MainMenuItem route="maps" text="Maps" id="maps" imagePath={PUBLICURL + "home/maps.png"}/>
        <MainMenuItem route="library" text="Library " id="lore" imagePath={PUBLICURL + "home/library.png"}/>
        <MainMenuItem route="campaign" text="Campaign" id="campaign" imagePath={PUBLICURL + "home/campaign.png"}/>
      </MainMenu>
      <img src={PUBLICURL + "home/bottom-deco.png"} className="main-page-logo-bottom"/>
    </App>
  },
  {
    path: "/maps",
      element:
      <App>
        <div className="nav">
          <div className='breadcrumbs'>
            <NavLink to="/" id="home">HOMEPAGE</NavLink>
            <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
            <NavLink to="/maps" id="active">MAPS</NavLink>
          </div>
          <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-maps.png"} /></div>
        </div>
        <MapsMenu />
      </App>
  },
  {
    path: "/library",
    element:
      <App>
        <div className="nav">
        <div className='breadcrumbs'>
          <NavLink to="/" id="home">HOMEPAGE</NavLink>
          <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
          <NavLink to="/library" id="active">LIBRARY</NavLink>
        </div>
        <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-lib.png"} /></div>
        </div>
        <LoreMenu>
          <MainMenuItem route="gods" text="Gods of All-Pantheon " id="gods" imagePath={PUBLICURL + "gods-menu.png"}/>
          <MainMenuItem route="empires" text="Empires " id="empires" imagePath={PUBLICURL + "empires-menu.png"}/>
          <MainMenuItem route="lang" text="Languages" id="lang" imagePath={PUBLICURL + "lang-menu.png"} />
          <MainMenuItem route="history" text="Historical Timeline " id="history" imagePath={PUBLICURL + "timeline-menu.png"}/>
          <MainMenuItem route="planes" text="Planes of Existence " id="planes" imagePath={PUBLICURL + "planes-menu.png"}/>
          <MainMenuItem route="races" text="Races & Cultures" id="races" imagePath={PUBLICURL + "races-menu.png"}/>
          <MainMenuItem route="general" text="About Hordarat " id="general" imagePath={PUBLICURL + "general-menu.png"}/>
        </LoreMenu>
      </App>
  },
  {
    path: "/campaign",
    element: 
    <App>
    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/campaign" id="active">CAMPAIGN</NavLink>
      </div>
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-campaign.png"} /></div>
    </div>
      <Party questData={getQuestsData()} cluesData={getCluesData()} recapData={getRecapData()} contactsData={getContactsData()} notesData={getNotesData()} scheduleData={getScheduleData()} deedsData={getDeedsData()} partyStatusData={getPartyStatusData()} />
    </App>
  },
  {
    path: "/library/gods",
    element: 
    <App>    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library">LIBRARY</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library/gods" id="active">GODS OF ALL-PANTHEON</NavLink>
      </div>
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-gods.png"} /></div></div>
      <GodsMenu data={getGodsData()} />
    </App>
  },
  {
    path: "/library/races",
    element: 
    <App>    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library">LIBRARY</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library/gods" id="active">RACES & CULTURES</NavLink>
      </div>
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-races.png"} /></div></div>
      <RacesMenu data={getRacesData()} appearData={getAppearanceData()}/>
    </App>
  },
  {
    path: "/library/planes",
    element: 
    <App>    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library">LIBRARY</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library/planes" id="active">PLANES OF EXISTENCE</NavLink>
      </div>      
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-planes.png"} /></div></div>
      <Planes map="planes.png" data={getPlanesData()}/>
    </App>
  },
  {
    path: "/library/empires",
    element: 
    <App>    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library">LIBRARY</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library/empires" id="active">EMPIRES</NavLink>
      </div>
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-empires.png"} /></div></div>
      <EmpiresMenu data={getEmpData()}/>
    </App>
  },
  {
    path: "/library/history",
    element: 
    <App>    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library">LIBRARY</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library/history" id="active">HISTORICAL TIMELINE</NavLink>
      </div>
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-history.png"} /></div></div>
      <History data={getEventsData()}/>
      <div className="help">{"BSF - Before Second Foundation \n ASF - After Second Fountation (current era)"}</div>
    </App>
  },
  {
    path: "/library/lang",
    element: 
    <App>    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library">LIBRARY</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library/history" id="active">LANGUAGES</NavLink>
      </div>
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-languages.png"} /></div></div>
      <Languages data={getLangData()}/>
    </App>
  },
  {
    path: "/library/general",
    element: 
    <App>    <div className="nav">
      <div className='breadcrumbs'>
        <NavLink to="/" id="home">HOMEPAGE</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library">LIBRARY</NavLink>
        <img className='breadcrumbs-divider' src={PUBLICURL + "arrow-right.png"}/>
        <NavLink to="/library/general" id="active">ABOUT HORDARAT</NavLink>
      </div>
      <div className="page-title"><img className="page-title-img" src={PUBLICURL + "headers/header-about.png"} /></div></div>
      <About data={getAboutData()}/>
    </App>
  },
]);

root.render(
  <React.StrictMode>
    <Loading>
      <RouterProvider router={router} />
      <div className="lazy">{"Pls flip back to landscape orientation or find a bigger screen. \n I am too lazy to do CSS for a portrait or mobile. \n At least for now... \n Soz :("}</div>
    </Loading>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//<RouterProvider router={router} />
//<Loading />
/*
      <div className="help">
        <div className="help-button"><img src={PUBLICURL + "help-button.png"} /></div>
        <div className="help-text">123123123</div>
      </div>
*/
