import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './RacesMenu.css';
import { PUBLICURL } from '../data/const';
import Collapsable from '../Collapsable/Collapsable';

class RacesMenu extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeIndex: "none",
      activeTabIndex:"Aasimars",
      showDetails: false
    };
    this.handleClick = this.handleClick.bind(this)
  }

  handleBottomClick() {
    this.state.showDetails ?
      this.setState({ 
        showDetails: false,
      }) : 
    this.setState({ 
      showDetails: true,
    }) 
  }

  handleClick(event) {
    this.setState({ 
      activeIndex: event,
      activeTabIndex: event
    })
  }

  handleTabClick(tab) {
    this.setState({ 
      activeTabIndex: tab
    })
  }

  render() {
    return (
      <>
        <div className="races">
          <div className="races-list" id="appear">
            <div className={this.state.activeIndex==="appear" ? "races-container-active" : "races-container-appear"} onClick={() => this.handleClick("appear")}>
                  <div className="races-name">Appearance options for races</div>
            </div> 
          </div>
          <div className="races-list">
            {
              this.props.data.map(t => 
              <div className={this.state.activeIndex===t.name ? "races-container-active" : "races-container"} onClick={() => this.handleClick(t.name)}>
                <div className="races-name">{t.name}</div>
              </div> 
              )
            }
          </div>


          <div className="races-details"> 
            <div className="appear-container">
            {
              this.state.activeIndex === "appear" ? 
              <>
              <div className="variant-warning" id="warning-appear">
                <img src={PUBLICURL + "warning-icon.png"} />
                Listed options are suggestions and not strict recommendations
              </div>
              {
                this.props.appearData.map(ap =>
                  <Collapsable name={ap.title} subtitle={ap.subtitle} type="list">
                  {
                    ap.content.map(n =>
                      <>
                        <div className="clues-bullet-descr-list"><b>{n.title}</b></div>
                        <ul><li>{n.text}</li></ul>
                      </>
                    )
                  }
                  </Collapsable>
                )
              }
              </> : <></>
            }
          </div>
          
          {
            this.state.activeIndex === "none" ?
            <div className="empty">
              <img src={PUBLICURL + "empty-states/empty-normal.png"} />
              Select item from the list
            </div> :
            this.props.data.filter(text => text.name===this.state.activeIndex).map(t => 
              <Fragment key={t.id}>
                <div className="details-tabs">
                  {
                    t.variants.map(v =>
                      <div className={this.state.activeTabIndex===v.variantName ? "details-name-active" : "details-name"}  onClick={() => this.handleTabClick(v.variantName)}>{v.variantName}</div>
                    )
                  }
                </div>
                {
                    t.variants.filter(text => text.variantName===this.state.activeTabIndex).map(v =>
                    <>
                      <div className="details-features" key={v.variantName}>
                        {
                          v.variantWarning ? 
                            <div className="variant-warning">
                              <img src={PUBLICURL + "warning-icon.png"} />
                              {v.variantWarning}
                            </div> : 
                              <></>
                        }
                        <div className="details-descr">{v.variantDescr}</div>
                        {
                          v.variantFeatures.filter( stf => stf.featureName === "Languages" || stf.featureName === "Ability Score Improvements" || stf.featureName === "Tools Proficiency" || stf.featureName === "Speed").map(vf =>
                            <Collapsable name={vf.featureName} subtitle="" descr="" type="list" id="collapsable-basic">
                              {
                                vf.bullets.map(f =>
                                    <>
                                        {
                                          <div className="clues-bullet-descr-list">{f.bullet}</div>
                                        }
                                    </> 
                                )
                              }
                            </Collapsable>
                          )
                        }
                        <div className="races-divider"></div>
                        {
                          v.variantFeatures.filter( stf => stf.featureName !== "Languages" && stf.featureName !== "Ability Score Improvements" && stf.featureName !== "Tools Proficiency" && stf.featureName !== "Speed").map(vf =>
                            <Collapsable name={vf.featureName} subtitle="Features & Traits" descr="" type="list">
                              {
                                vf.bullets.map(f =>
                                    <>
                                        {
                                          f.isMultiLevel==="true" ? <ul><li>{f.bullet}</li></ul> : <div className="clues-bullet-descr-list">{f.bullet}</div>
                                        }
                                    </> 
                                )
                              }
                            </Collapsable>
                          )
                        }
                      </div>
                      </>

                    )
                  }

              </Fragment>)
          }
          </div>
        </div>
      </>
    );
  }
}

export default RacesMenu;