import './MainMenu.css';
import { PUBLICURL } from '../data/const';

function MainMenu({ children }) {
  return (
    <div className="menu">
      {children}
    </div>
  );
}

export default MainMenu;
