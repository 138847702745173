export const getDeedsData = () => [
    {
        players:[
            "Campaign started: January 19th, 2022 \n",
            "Games played: 17 \n\n",
            "D&D Edition: 5e (2014)",
        ],
        deeds:[
            "- Helped Felix Rodas fight off his pursuers and safely return to Koris \n",
            "- Met goliath clan Cyrek led by Saba\n",
            "- Bribed the guards in Stravarod in order to get into the city\n",
            "- Saved the life of Oria, one of Felix'ship crewmembers\n",
            "- Sold a ton of crossbows to a chemist in Stravarod\n",
            "- Terminated a rogue Arsarian spy operating in Koris \n",
            "- Illegaly broke into Low Courthouse of Koris \n",
            "- Met all current heads of Founding Families of Koris\n",
            "- Saved Lord & Lady Bialas from Trygg raiders \n",
            "- Found a Pale Tree \n",
            "- Found Hypogeal Retreat, underground complex of Darius Fers \n",
            "- All members of the party used their blood to explore Hypogeal Retreat \n",
            "- Found Darius Fers' diary and took some notes \n",
            "- Refused Wayfinder's offer to release it in exchange for some favor \n",
            "- Released Sekath, an ancient Kor blood mage and a caretaker of Hypogeal Retreat \n",
            "- Partially awakened twins' book, En'Chiridia \n",
            "- Partially awakened Samar's dagger, Blade of Coercion \n",
            "- Used Arcane Slingshot to escape from Hypogeal Retreat \n",
            "- Escaped Pentagar Pillars undetected \n",
            "- Roxana Laugen, head of Koris' Laugen family, entrusted the party with the delivery of a Unity Beacon, a dwarven artefact of great importance, to Himeldan \n",
            "- Assisted Derryn of Himeldan in replacing protective wards in Old Jork \n",
            "- Sonya disappeared \n",
            "- Bronx was awakened by Samar\n",
            "- Rock dragon was healed and released from the trap\n",
            "- Primarch of Himeldan is convinced to redeem and to wait\n",
            "- Resurrected Brandal, captain of Himeldan Watchers",
        ],
        list:[
            "- Felix Rodas' pursuers back in Eastern Marches\n",
            "- Bombards, a gang of brothers that specialized in bombs and alchemy\n",
            "- Verida, a rogue spy from Arsaria\n",
            "- Numerous Trygg invaders that attacked Lord and Lady Bialas' manor\n",
            "- Ametrine Legionnaire",
        ],
        deaths:[
            "Deaths: 0 \n",
            "Awful but still funny deaths: 0\n",
            "Successful resurrections: 1 \n",
            "Failed resurrections: 0 \n\n",
            "Healed NPCs: at least 5 or so",
        ],
        lastUpdated:"Deeds updated - November 30th, 2023",
        crime:[
            "Break-ins (incl. government buildings): 2 \n",
            "Escapes (incl. government buildings): 1\n",
            "Assassinations: 0 \n",
            "Disturbed tombs: 1 \n",
            "Successful bribes: 1 \n",
            "Failed bribes: 0 \n",
            "Orphanages set on fire: 0\n",
            "Orphanages set on fire accidentally: 0\n",
            "Temples desecrated: 0 \n",
            "Noblemen insulted: 0 \n",
            "Noblemen shit-kicked: 0 \n\n",

            "Times shitfaced: 1\n",
            "Times shitfaced and still got the job done: 0\n\n",

            "Gold stolen: N/A",
        ],
        seks:[
            "Brothels visited: 1 (duh...) \n",
            "Brothels visited accidentally: 1 \n",
            "Hooker count: 5",
        ],
        pets:[
            "Pets owned: 2 \n",
            "Pets died: 0 (so far)",,
        ],
        exploration:[
            "Settlements visited: 3 \n",
            "Sewers visited: 0 \n",
            "Libraries visited: 1 \n",
            "Temples visited: 1 \n\n",
            "Pale Trees found: 1 \n",
            "Pale Trees used: 1",
        ],
        food:[
            "Animals hunted & eaten: a couple of rabbits (courtesy of Bronx) \n",
            "Traveller's ales consumed: 1 \n",
            "Drugs consumed: 0\n",
            "Food poisonings: 0\n",
            "Food poisonings caused: 0",
        ],
        jobs:[
            "Koris Forum tournaments completed: 0\n",
            "Mercenary jobs completed: 2\n",
            "Underground jobs completed: 0",
        ],
        currency:[
            "Gold earned: I really don't remember \n",
            "Gold spent: Not my job",
        ],
        divinearcane:[
            "Visions received by Aedis: 2\n",
            "Visions received by Bronx: 1\n",
            "Visions received by Firmi: 2\n",
            "Visions received by Samar: 2\n",
            "Visions received by Sonya: 0",
        ],
        risky:[
            "Barged into some wizard's hideout and broke something: 1 \n",
            "Stolen items from aforementioned wizard's hideout: Yes \n",
            "Almost got a soul sold to a demon: 1 \n",
            "Awoken someone you were not supposed to: 1 \n",
            "Freed creatures of questionable morality: 1 \n",
            "Broken important-looking artefacts in general: 1 \n",
            "Times own blood was used for something other than living: 5\n\n",
            "Counts of casual racist quips: they haven't invented enough numbers for that yet"
        ],
        time:[
            "Campaign started (in-game): (October 15th, 2103 ASF) \n",
            "Current date (in-game): (December 15th, 2103 ASF) \n\n",
            "In-game days spent: 56 \n",
            "In-game days spent travelling: 20",
        ],
        other:[
            "Tattoos aquired: 0\n",
            "Tattoos aquired when drunk: 0",
        ],
        rolls:[
            "Three Natural 1s in a row: 1 \n",
            "Three Natural 20s in a row: 0",
        ],
    },
]
