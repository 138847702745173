export const getAppearanceData = () => [
    {
        id:"aasimars",
        title:"Aasimars",
        subtitle:"Base race",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"x1.5 of regular human",
            },
            {
                title:"Height range",
                text:"6-7 ft (180-210 cm)",
            },
            {
                title:"Weight range",
                text:"150-300 lbs (70-130 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"White, pale white, beige",
            },
            {
                title:"Usual eye color",
                text:"White, grey, slightly gold, silvery",
            },
        ]
    },
    {
        id:"eventide-aasimars",
        title:"Aasimars - Eventide Aasimars",
        subtitle:"Alternative race for aasimars",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"x2 of regular human",
            },
            {
                title:"Height range",
                text:"6-7 ft (180-210 cm)",
            },
            {
                title:"Weight range",
                text:"150-300 lbs (70-130 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Dark grey, black",
            },
            {
                title:"Usual eye color",
                text:"White, black, silvery, golden",
            },
        ]
    },
    {
        id:"dwarves",
        title:"Dwarves",
        subtitle:"Base race & variant races",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Up to 500 years, mature at 100",
            },
            {
                title:"Height range",
                text:"4-5 ft (120-150 cm)",
            },
            {
                title:"Weight range",
                text:"150-200 lbs (70-90 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any",
            },
            {
                title:"Usual eye color",
                text:"Any, eyes also have a noticable spark",
            },
        ]
    },
    {
        id:"elves",
        title:"Elves",
        subtitle:"Base race",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Up to 750 years, mature at 250",
            },
            {
                title:"Height range",
                text:"5-7 ft (120-210 cm)",
            },
            {
                title:"Weight range",
                text:"100-150 lbs (45-70 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any",
            },
            {
                title:"Usual eye color",
                text:"Any",
            },
        ]
    },
    {
        id:"maelstrom-elves",
        title:"Elves - Maelstrom Elves",
        subtitle:"Varian race for elves",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Up to 750 years, mature at 250",
            },
            {
                title:"Height range",
                text:"5-7 ft (120-210 cm)",
            },
            {
                title:"Weight range",
                text:"100-150 lbs (45-70 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any",
            },
            {
                title:"Usual eye color",
                text:"Any",
            },
        ]
    },
    {
        id:"drow-elves",
        title:"Elves - Drow Elves",
        subtitle:"Variant race for elves",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Up to 500 years, mature at 150",
            },
            {
                title:"Height range",
                text:"5-7 ft (120-210 cm)",
            },
            {
                title:"Weight range",
                text:"100-150 lbs (45-70 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Dark grey, black",
            },
            {
                title:"Usual eye color",
                text:"Grey white",
            },
        ]
    },
    {
        id:"goliaths",
        title:"Goliaths",
        subtitle:"Base race & variant races",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"x1.5 of regular human",
            },
            {
                title:"Height range",
                text:"6-8.5 ft (180-255 cm)",
            },
            {
                title:"Weight range",
                text:"280-340 lbs (120-150 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any",
            },
            {
                title:"Usual eye color",
                text:"Any",
            },
        ]
    },
    {
        id:"humans",
        title:"Humans",
        subtitle:"Base race",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Regular human livespan",
            },
            {
                title:"Height range",
                text:"5-7 ft (150-210 cm)",
            },
            {
                title:"Weight range",
                text:"120-250 lbs (50-110 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any",
            },
            {
                title:"Usual eye color",
                text:"Any",
            },
        ]
    },
    {
        id:"humans-old-blood",
        title:"Humans - Old Blood",
        subtitle:"Variant race for humans",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"x2 of regular human",
            },
            {
                title:"Height range",
                text:"6-7.5 ft (180-255 cm)",
            },
            {
                title:"Weight range",
                text:"120-250 lbs (50-110 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any",
            },
            {
                title:"Usual eye color",
                text:"Any, iris has a golden tone to it",
            },
        ]
    },
    {
        id:"kor",
        title:"Kor",
        subtitle:"Base race",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Slighly more than a regular human",
            },
            {
                title:"Height range",
                text:"6-8 ft (150-180 cm)",
            },
            {
                title:"Weight range",
                text:"120-250 lbs (50-110 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Light grey, grey, pale white",
            },
            {
                title:"Usual eye color",
                text:"Cold white, grey, blue, emerald green",
            },
        ]
    },
    {
        id:"tieflings",
        title:"Tieflings",
        subtitle:"Base race",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Slighly more than a regular human",
            },
            {
                title:"Height range",
                text:"5-7 ft (150-210 cm)",
            },
            {
                title:"Weight range",
                text:"120-250 lbs (50-110 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Purple, red, dark blue, pink",
            },
            {
                title:"Usual eye color",
                text:"Red, orange, golden yellow",
            },
        ]
    },
    {
        id:"tieflings-sepid",
        title:"Tieflings - Sepid Tieflings",
        subtitle:"Variant race for tieflings",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Slighly more than a regular human",
            },
            {
                title:"Height range",
                text:"5-7 ft (150-210 cm)",
            },
            {
                title:"Weight range",
                text:"120-250 lbs (50-110 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"White, light grey, light purple",
            },
            {
                title:"Usual eye color",
                text:"Red, orange, golden yellow",
            },
        ]
    },
    {
        id:"warforged",
        title:"Warforged",
        subtitle:"Base race",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Ageless",
            },
            {
                title:"Height range",
                text:"5-8 ft (150-240 cm)",
            },
            {
                title:"Weight range",
                text:"120-250 lbs (50-110 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any color of any metal",
            },
            {
                title:"Usual eye color",
                text:"Any",
            },
        ]
    },
    {
        id:"warforged-colossus",
        title:"Warforged -  Specialized Construct - Colossus Warforged",
        subtitle:"Variant race for warforged",
        isMultiLevel: "true",
        content:[
            {
                title:"Average live expectancy",
                text:"Ageless",
            },
            {
                title:"Height range",
                text:"7-9 ft (210-270 cm)",
            },
            {
                title:"Weight range",
                text:"400-600 lbs (180-270 kg)",
            },
            {
                title:"Usual skin &  hair color",
                text:"Any color of any metal",
            },
            {
                title:"Usual eye color",
                text:"Any",
            },
        ]
    },

]