export const getAboutData = () => [
    {
        id:"first",
        blocks:[
            {
                text:"pee pee1",
                icon:"",
            },
            {
                text:"pee pee2",
                icon:"",
            },
            {
                text:"pee pee3",
                icon:"",
            },
        ]
    },
    {
        id:"second",
        blocks:[
            {
                text:"poo poo1",
                icon:"",
            },
            {
                text:"poo poo2",
                icon:"",
            },
            {
                text:"poo poo3",
                icon:"",
            },
        ]
    },
    {
        id:"third",
        blocks:[
            {
                text:"puu puu1",
                icon:"",
            },
            {
                text:"puu puu2",
                icon:"",
            },
            {
                text:"puu puu3",
                icon:"",
            },
        ]
    },
]