export const getPlanesData = () => [
    {
        tabId:"inner",
        image:"planes/details/inner.png",
        planes:[
            {
                name:"Hordarat",
                alias:"Material Plane / Mortal Dominion",
                description:"Hordarat, often incorrectly translated as “Tides of Creation”, but this translation is neverthenless accurate in its message. This world is the center of the creative chaos, and is the most ambitions project of the higher powers there is. \n \n Incredibly diverse and populated, this is a home of Fourth Children, gods' latest attempt to create sentient life, and so far the most successful. The world currently experiencing the Age of Restoration, a period of long-awaited peace after centuries of turmoil and violence caused by severed connection between All-Pantheon and mortals 6000 years ago. Gods are now back with the mortals, and both parties are now trying to learn how to interact with each other anew.",
                icon: "planes/hordarat.png",
            },
            {
                name:"Vessen",
                alias:"Moon",
                description:"An eternal companion of Hordarat, Vessen is the blue rocky moon that rotates around the Hordarat. There are numerous records of some kind of constructions built on its surface, most notably a giant obsidian tower - The Spire of Vessen - rooted deep within the moon. \n \n The Spire is a popular destination of various expeditions conducted by arcane communities, though only handful of them managed to reach the outskirts of the complex, eventually failing on a closer approach.",
                icon: "planes/vessen.png",
            },
            {
                name:"Aevintyr",
                alias:"World of changelings",
                description:"World of changelings, The First Children created by gods, a place of moving continents, forests and fields. Lush and vibrant flora and fauna are a sight to see, but one traveling there must remember to not trust the environment. Every leaf, every blade of grass, every stone, every sea could be a changeling whose motives are unknown. \n \n Incredibly complicated interacting ecosystems are constantly shifting and changing, making it almost impossible to map out the Aevintyr, no to mention to navigate across this plane.",
                icon: "planes/aevintyr.png",
            },
            {
                name:"Balaur",
                alias:"World of dragons",
                description:"World of dragons, Second Children created by the gods, siblings to the changelings of Aevintyr. This place also serves as a prison-realm for the souls of oathbreakers, traitors and kin killers that are being constantly hunted and consumed by the dragons. It is a vicious and a competitive world of fire and rock that knows no mercy or any sense of trustworthiness. Violent dragon cults, grandiouse mountain nests build by a slave soul labor, millions of dead souls consumed with no hope for return, constant powerplay among various dragon lineages, fanatics, futile resistances driven by the mortal souls against their draconic overlords - this is the true hell of the cosmos. \n \n Nazamuth, The Despot of Balaur, The Umbral Claw of Tyranny, The Second Son was ruling over Balaur ever since the creation of this world. His domain was strong until his eventual demise by the hand of the entire All-Pantheon several millenias ago, also called as Balaur Reprisal. Nazamuth’s envy and ego went too far, and he was ultimately punished for it. During the final battle, Caertana, Goddess of Judgement, was severly wounded while delivering the lethal blow to the Despot.",
                icon: "planes/balaur.png",
            },
            {
                name:"Kaeldom",
                alias:"Dead world",
                description:"Empty shell of a realm, a destroyed home of the Missing Progeny, now-extinct species of the Third Children created by the All-Pantheon. Not much known about them, or what led to the devastation of the realm, or if there are any remnants of the Progeny left. One thing is sure, this world was devastated by a war of untold scale. Planetary core is exposed as the surface of the planet cracked under the pressure of unstoppable magics unleashed by the former inhabitants of this world. Giant autonomous arcane cannons still firing their magical rounds across the devastated continents,  fields are still covered in dangerous sigils, areas enchanted with deadly power words, necrotic catapults still carrying dangerous ammunition. \n \n Masters of this world have been dead for eons, but the machines are still at war. A heavily weaponized piece of cosmic rock that allows for no guests, no life, only for an endless, forgotten and pointless fight. This, however, does not stop various groups from venturing into interplanar expeditions searching for powerful artifacts and implements of war.",
                icon: "planes/kaeldom.png",
            },
            {
                name:"Ringworld of Cadence",
                alias:"God-space of Timthirial, God of Nichtemeron",
                description:"Plane of Timthirial, God of Nichtemeron, who is ruling over a ringworld rotating around a small star and generating cosmic energies that feed into the Sun. It is also responsible for the day & night cycle and planetary movement of the realms of the Inner Astral Sea, as well for the existence of other smaller stars across the Astral Sea.\n \n The stars are noticeably pulsating with a constant minute-long interval, also called the Celestial Heartbeat. Discovery of this phenomenon millennia ago led to the adoption of a 24-hour timekeeping convention used by inhabitants of Hordarat and the creation of a standard 10-month calendar. Practically every basic aspect of mortal existence depends on the rythm of the Sun and day-night rotation, hence making Timthirial the  most powerful and most popular deity among mortals.",
                warning:"Ringworld of Cadence is not accessible via Plane Shift spell or other means of interplanar transportation. Attempting to do so will result in transporting onto the surface of the Sun.",
                icon: "planes/ringworld.png",
            },
            {
                name:"Concordant Skyways",
                alias:"God-space of Vigsla, Goddess of Pacts",
                description:"Plane of Vigsla, Goddess of Pacts, who is looking over a cosmic highway of various energy bridges that lead to other godly planes of existence. It serves as a stable node that connects the divinity and mortal realms. It is responsible for establishing a bandwidth that allows for interactions between mortals and majority of the All-Pantheon, while Vigla herself serves as a powerbroker between two parties. \n \n It is believed that the souls of the tieflings always end up here first as they are eternally bound to Vigsla regardless of their commitment to other gods. Same souls would also sort and acompany other beings traveling towards other planes of existence.",
                warning:"Concordant Skyways are accessible via Plane Shift spell or other means of interplanar transportation.",
                icon: "planes/skyways.png",
            },
        ]

    },
    {
        tabId:"outer",
        image:"planes/details/outer.png",
        planes:[
            {
                name:"Gauntlet of Vehemence",
                alias:"God-space of Tjon, Goddess of Havoc",
                description:"Plane of Tjon, Goddess of Havoc. An endless battlefield with unreachable golden palace in the middle. Red skies in a constant turmoil. Numerous armies of dead warrior souls from every place and time are eternally fighting trying to reach that place in hope to find some afterlife peace there.  \n \n Majority of these stories come from the members of Mayhem Cults (devoted to worship the aspect of violence that Tjon often represents), whose entire goal is to reach the Gauntlet and prove their perseverance to the Mother of Mayhem through combat.",
                icon: "planes/gauntlet.png",
            },
            {
                name:"Compendium Sphere [Icon WIP]",
                alias:"God-space of Fraedingur, God of Lore",
                description:"Plane of Fraedingur, God of Lore. It was originally imagined as an endless library that contains every bit of information that exists in the universe. But  records of the recent expeditions describe a white smooth stone-like sphere with no vegetation or buildings. No life populates this world, only tall smooth memorial obelisks containing some texts in unknown language.  \n \n Some accounts also tell about constant winds blowing across the land that almost whisper to the visitors, but the meaning of those whispers is quite illusive. Worth mentioning that any records of this place tend to mysteriously disappear over time, as well as the visitors of the plane rapidly succumb into amnesia.",
                icon: "planes/indemnity.png",
            },
            {
                name:"Tidal Forum [Text & icon WIP]",
                alias:"God-space of Caertana, Goddess of Judgement",
                description:"[Text & icon WIP]",
                icon: "planes/indemnity.png",
            },
            {
                name:"Sempinternal Cenotaph",
                alias:"God-space of Roja, God of Tranquility",
                description:"Plane of Roja, God of Tranquility. A endless network of canyons, dusty valleys, vast deserts and memorials complexes full of gargantuan statues of those passed away.  \n \n All paths converge in the middle of the plane within Roja’s burial complex known as Cenotapth, where caretaker souls prepare newcomers and help them to readjust in the afterlife.",
                icon: "planes/cenotaph.png",
            },
            {
                name:"Semiplanar Throne of Vigor",
                alias:"God-space of Firmi, the first Twin God",
                description:"(most of the records of this plane were destroyed at the end of the First Age of Twin Gods by unknown agents)",
                icon: "planes/firmi-plane.png",
            },
            {
                name:"Semiplanar Throne of Grit",
                alias:"God-space of Aedis, the second Twin God",
                description:"A plane of Aedis that is somehow connected to the similar plane of Aedis' twin, Firmi. This world was never visited by mortals, but some of them experienced a vision that would describe the plane. Those dreams tell about an endless field made of stone, in the middle of which situated a gargantuan throne of Aedis, where the god himself resides. \n \n The landspace of this place is constantly moving and readjusting, building something and disassembling back. It seems that Second Twin might be experimenting with the forms and shapes of his own plane for unknown purpose.",
                icon: "planes/aedis-plane.png",
            },
            {
                name:"Pagonia of Convalescence",
                alias:"Merged plane of Certana & Fraedingur",
                description:"A merged plane created by both Caertana and Roja for the souls of those who was betrayed or lied to in life. A world full of healing springs, a place where those souls are trying to restrore physically and mentally by entering the coma-like state.  \n \n Some souls manage to recover and even return to the mortal plane to tell the tale. Some decide to stay and become caretakers to help others. But some are so gravely wounded, they most likely will never wake up from their eternal slumber in the springs.",
                icon: "planes/pagonia.png",
            },
            {
                name:"Plains of Indemnity [Icon WIP]",
                alias:"Merged plane of Roja & Fraedingur",
                description:"A merged plane rumored to be created by Fraedingur and Roja to keep the memories of the dead, but those rumors are based on loose visions experienced by random individuals, and pattern of the visions is still unknown. There are no records of anyone ever visiting this plane.",
                icon: "planes/indemnity.png",
            },
        ]

    },
    {
        tabId:"edge",
        image:"planes/details/edge.png",
        planes:[
            {
                name:"Streams of Alteration / Drifting Veil of the Void [Icon WIP]",
                alias:"God-space of Juvena, Goddess of Transference",
                description:"Ever drifting plane of Juvena, Goddess of Transference. A collection of incredibly long energy leylines constantly moving by the edge of the Void. \n \n There are no known records of what exactly is this plane, except that it is vastly dangerous due to the proximity of the Void.",
                icon: "planes/indemnity.png",
            },
            {
                name:"Lethargic Veldt [Icon WIP]",
                alias:"Dead world",
                description:"A dead world of unknown origin. This world seems to drift alongside one of the tails of the Stream of Alteration.",
                icon: "planes/indemnity.png",
            },
            {
                name:"Null-Eye",
                alias:"Manifestation of Void",
                description:"The only known constant cosmic object manifested by the Void, which introduces a paradox since Void is believed to be the opposite of being static. A black world covered in constantly moving flesh-like substance that forms a shape of a disgusting eye. It does not make sense for this place to exist as it is hard to fathom why the terrors of the Void had a need to create something so stationary. \n \n This plane is also visible at night even from Hordarat, though it would require some powerful observatory. Nonetheless the fact that an object of this nature is visible from so far away is unsettling enough to consider this place a dangerous one.",
                icon: "planes/nulleye.png",
            },
            {
                name:"The Void [Icon WIP]",
                alias:"Endless Cosmos",
                description:"Entropy incarnate. An endless mass of darkness of the unversal scale, constantly changing, reacting, expanding and retracting. Undescribable interdimensional eldritch horror resides there. It has no form and no single mind, it is everything and nothing at the same time. \n \n This is the source of the most terrific destructive and creative powers as it knows no bounds and is only driven by the basic instinct of engulfing everything that exists, creating an endless cycle of creation and destruction. The Sun, Timrhirial and various stars created by the sun god seem to keep the Void at bay and away from this section of Astral Sea.",
                icon: "planes/indemnity.png",
            },
        ]

    },
]