import { NavLink } from 'react-router-dom';
import './LoreMenu.css';

function LoreMenu({ children }) {
  return (
    <div className="lore-menu">
      {children}
    </div>
  );
}

export default LoreMenu;