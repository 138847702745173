import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import './GodsMenu.css';
import { PUBLICURL } from '../data/const';
import Collapsable from '../Collapsable/Collapsable';

class GodsMenu extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      activeIndex: "none"
    };
    this.handleClick = this.handleClick.bind(this)
  }



  handleClick(god) {
    this.setState({ 
      activeIndex: god
    })
  }

  render() {

    return (
      <>
        <div className="gods-menu">
          <div className="gods-list"> 
            <div className="god-container" id={this.state.activeIndex==="tim" ? "img-tim-active" : "img-tim"} onClick={() => this.handleClick("tim")}>
              <img src={PUBLICURL + "gods/gods_tim.png"} />
              <div className="god-name">
                <span>TIMTHIRIAL</span>
                <span id="god-title">{"God of \n Nichtemeron"}</span>
              </div>
            </div> 
            <div className="god-container"id={this.state.activeIndex==="vigsla" ? "img-vigsla-active" : "img-vigsla"} onClick={() => this.handleClick("vigsla")}>
              <img src={PUBLICURL + "gods/gods_vigsla.png"} />
              <div className="god-name">
                <span>VIGSLA</span>
                <span id="god-title">{"Goddess of\nPacts"}</span>
              </div>
            </div> 
            <div className="god-container"id={this.state.activeIndex==="caertana" ? "img-caertana-active" : "img-caertana"} onClick={() => this.handleClick("caertana")}>
              <img src={PUBLICURL + "gods/gods_caertana.png"} />
              <div className="god-name">
                <span>CAERTANA</span>
                <span id="god-title">{"Goddess of \nJudgemen"}</span>
              </div>
            </div> 
            <div className="god-container"id={this.state.activeIndex==="juvena" ? "img-juvena-active" : "img-juvena"} onClick={() => this.handleClick("juvena")}>
              <img src={PUBLICURL + "gods/gods_juvena.png"} />
              <div className="god-name">
                <span>JUVENA</span>
                <span id="god-title">Goddess of Transference</span>
              </div>
            </div> 
            <div className="god-container"id={this.state.activeIndex==="roja" ? "img-roja-active" : "img-roja"} onClick={() => this.handleClick("roja")}>
              <img src={PUBLICURL + "gods/gods_roja.png"} />
              <div className="god-name">
                <span>ROJA</span>
                <span id="god-title">God of Tranquility</span>
              </div>
            </div> 
            <div className="god-container"id={this.state.activeIndex==="tjon" ? "img-tjon-active" : "img-tjon"} onClick={() => this.handleClick("tjon")}>
              <img src={PUBLICURL + "gods/gods_tjon.png"} />
              <div className="god-name">
                <span>TJON</span>
                <span id="god-title">Goddess of Havoc</span>
              </div>
            </div> 
            <div className="god-container"id={this.state.activeIndex==="fraed" ? "img-fraed-active" : "img-fraed"} onClick={() => this.handleClick("fraed")}>
              <img src={PUBLICURL + "gods/gods-fraed.png"} />
              <div className="god-name">
                <span>FRAEDINGUR</span>
                <span id="god-title">God of Lore</span>
              </div>
            </div> 
            <div className="god-container"id={this.state.activeIndex==="firmi" ? "img-firmi-active" : "img-firmi"} onClick={() => this.handleClick("firmi")}>
              <img src={PUBLICURL + "gods/gods_firmi.png"} />
              <div className="god-name">
                <span>FIRMI</span>
                <span id="god-title">{"Twin-God of\nStillness"}</span>
              </div>  
            </div> 
            <div className="god-container"id={this.state.activeIndex==="aedis" ? "img-aedis-active" : "img-aedis"} onClick={() => this.handleClick("aedis")}>
              <img src={PUBLICURL + "gods/gods_aedis.png"} />
              <div className="god-name">
                <span>AEDIS</span>
                <span id="god-title">{"Twin-God of\nContinuity"}</span>
              </div>
            </div> 
          </div>
          <div className="gods-details"> 
          {
            this.state.activeIndex === "none" ?
            <div className="empty">
              <img src={PUBLICURL + "empty-states/empty-vertical.png"} />
              Select item from the list
            </div> :
            this.props.data.filter(text => text.id===this.state.activeIndex).map(t => 
              <Fragment key={t.id}>
                <div className="details-name">{t.name}</div>
                <div className="gods-detail-content">
                  <div className="details-descr">{t.descr}</div>
                  <Collapsable name="Domains" subtitle="" descr={t.domains} ></Collapsable>
                  <Collapsable name="Worship" subtitle="" descr={t.worship}></Collapsable>
                  <Collapsable name="Alternative names" subtitle="" descr={t.alias}></Collapsable>
                  <Collapsable name="Plane of existence" subtitle="" descr={t.planes}></Collapsable>       
                </div>
         
              </Fragment>)
          }
          </div>
        </div>
      </>
    );
  }
}

export default GodsMenu;
//<img src={PUBLICURL + t.image} />