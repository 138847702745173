export const getRecapData = () => [
    {
        id:"recap",
        description:"After investigating Megalith Bastion and reasoning with the Primarch, the next two weeks of your stay in Himeldan were quite productive.\n\n Bronx discovered several clue related to his past. With the help of one of Atheneum's curators - PJ - he obtained a broken bagpipe found in a warforged tomb similar to his own. You can check the details in the notes below. \n\n During that time, Aedis was contacted by several contacts. First, seemingly drunk Roxana Laugen asked for a status update, and after hearing Aedis out, she suggested to send reinforcmenets, but Primarch forced her to back off. Second, Azal Rafis, ambassador of Western League, inquired about Kalim and offered an ivitation to Koris to discuss him in private. \n\nParty have also interrogated Kalim himself and remains of his past personas by using Speak with Dead. He revealed numerous clue about his own past, Old Covenant, Cabal of Seclusion and Patria, a female wizard of old who is behind the curse Darius is suffering from. You can check other details in the session notes below.\n\n Firmi tried to contact Patria - only to receive a soul-piercing scream in Thanasian language as a response. Once again, you can check details in the session notes below. \n\n Varid's research of the Unity Beacon has hit the wall as he discovered that the Beacon was supposed to be active, but it seemingly remained dormant. Only the close presense of the Twin's book - En'Chiridia - had a noticeable effect as the cover fo the book transformed into a some sort of socket. Book accepted the beacon and produced a compass-like pointer leading the party to Atheneum. Once arrived to the library, party has found a secret entrance leading to a room with wide and almost bottomless well taking most of the floor.\n\n This is where we pick up the story. \n--",
        lastUpdate: "Recap of previous game (#17)",
        partyLevel:"5th level"
    }
]
export const getPartyStatusData = () => [
    {
        character:"Aedis of Arsaria",
        status:"Alive",
        icon:"party/party-aedis.png"
    },
    {
        character:"Bronx",
        status:"Alive",
        icon:"party/party-bronx.png"
    },
    {
        character:"Firmi of Arsaria",
        status:"Alive",
        icon:"party/party-firmi.png"
    },
    {
        character:"Samar",
        status:"Alive",
        icon:"party/party-samar.png"
    },
    {
        character:"Sonya van Rintz",
        status:"Unknown",
        icon:"party/party-sonya.png"
    },
]